import React, { FC } from 'react';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import logo from 'assets/Fiction_Logo_Full_Red.png';

const { Title } = Typography;

import styles from './index.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const NotFound: FC<Props> = () => {
  return (
    <div className={styles.NotFound}>
      <div className={styles.header}>
        <img src={logo} alt="Fiction Software" className={styles.logo} />
      </div>
      <img src="/not-found.png" alt="Not Found" className={styles.image} />
      <Title level={3}>Looks like you are lost.</Title>
      <Link to="/">
        <Button shape="round" type="primary" size="large">
          Return Home
        </Button>
      </Link>
    </div>
  );
};
