import React, { FC, useEffect, useState } from 'react';

import logo from 'assets/Fiction_Logo_Full_Red.png';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import { ColorCode } from '../../../models/enums/color-code';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'stores/selectors/user';
import axios from 'axios';
import { useAuthentication } from 'components/common/AuthHook/useAuthentication';

import styles from './index.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const Subscribed: FC<Props> = () => {
  const [success, setSuccess] = useState(false);
  const user = useSelector(selectCurrentUser);
  const { loading } = useAuthentication();

  useEffect(() => {
    if (user !== undefined && user.email !== undefined) {
      axios
        .post(`https://us-central1-draft-craft.cloudfunctions.net/user/sendgrid`, {
          email: user?.email,
          customFields: {
            w4_T: 'true', // subscriber?
          },
        })
        .then(() => setSuccess(true))
        .catch((error: any) => {
          setSuccess(true);
          console.error(error);
        });
    }
  }, [user]);

  return (
    <div className={styles.Subscribed}>
      <ConfettiExplosion
        colors={[
          ColorCode.RED,
          ColorCode.ORANGE,
          ColorCode.YELLOW,
          ColorCode.GREEN,
          ColorCode.BLUE,
          ColorCode.VIOLET,
          ColorCode.PINK,
          ColorCode.PURPLE,
        ]}
      />
      <img src={logo} alt="Fiction Software" className={styles.img} />
      <div className={styles.success__message}>You have successfully subscribed!</div>
      {(!success || loading) && <div>Setting up your account...</div>}
      {success && (
        <Link to="/projects">
          <Button type="primary" shape="round" size="large">
            Go to projects
          </Button>
        </Link>
      )}
    </div>
  );
};
