import { createAsyncThunk } from '@reduxjs/toolkit';
import { Errors } from '../../models/enums/error';
import { Storyboard } from '../../models/interfaces/storyboard';
import {
  firebaseCollectionRequest,
  firebaseCreateDocument,
  firebaseDeleteDocument,
  firebaseUpdateDocument,
  getStoryboardsPath,
} from '../../services/database-service';
import { getProjectId } from './characters';
import { getUserId } from './projects';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';

/**
 * Create a new Storyboard
 */
export const createStoryboard = createAsyncThunk<Partial<Storyboard>, Partial<Storyboard>>(
  'NEW_STORYBOARD',
  (storyboard: Partial<Storyboard>, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    const projectId = getProjectId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined) {
      firebase.analytics().logEvent('create_content', { content_type: 'storyboards' });
      return firebaseCreateDocument<Partial<Storyboard>>(getStoryboardsPath(userId, projectId), storyboard);
    } else {
      throw Errors.USER_OR_PROJECT_UNDEFINED;
    }
  },
);

//Async Action Creators
/**
 * Fetch all storyboards from the Storyboards collection
 */
export const fetchStoryboards = createAsyncThunk<Storyboard[], string>('FETCH_STORYBOARD', (projectId, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined) {
    return firebaseCollectionRequest<Storyboard>(getStoryboardsPath(userId, projectId));
  } else {
    throw Errors.USER_OR_PROJECT_UNDEFINED;
  }
});

/**
 * Update an existing Storyboard
 */
export const saveStoryboard = createAsyncThunk<Partial<Storyboard>, Partial<Storyboard>>(
  'UPDATE_STORYBOARD',
  (storyboard: Partial<Storyboard>, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    const projectId = getProjectId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined) {
      firebase.analytics().logEvent('update_content', { content_type: 'storyboards' });
      return firebaseUpdateDocument<Partial<Storyboard>>(getStoryboardsPath(userId, projectId), {
        ...storyboard,
        updated: moment.now(),
      });
    } else {
      throw Errors.USER_OR_PROJECT_UNDEFINED;
    }
  },
);

/**
 * Delete an existing Storyboard
 */
export const deleteStoryboard = createAsyncThunk('DELETE_STORYBOARD', (storyboard: Partial<Storyboard>, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  const projectId = getProjectId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined && storyboard.id) {
    return firebaseDeleteDocument(getStoryboardsPath(userId, projectId), storyboard.id);
  } else {
    throw Errors.USER_OR_PROJECT_UNDEFINED;
  }
});

export const setEditingStoryboards = createAction<boolean | undefined>('SET_EDITING_STORYBOARD');

export const setTemporaryStoryboard = createAction<Partial<Storyboard> | undefined>('SET_TEMPORARY_STORYBOARD');

export const setIsCreating = createAction<boolean | undefined>('SET_IS_STORYBOARD_CREATING');
