import { createReducer, isAnyOf, SerializedError } from '@reduxjs/toolkit';
import { createNewArtifact, deleteExistingArtifact, saveArtifact } from 'stores/actions/artifacts';
import { fetchChapters, saveChapter } from 'stores/actions/chapters';
import { createNewCharacter, deleteExistingCharacter, saveCharacter, fetchCharacters } from 'stores/actions/characters';
import {
  createNewRelationshipGroup,
  deleteExistingRelationshipGroup,
  fetchRelationshipGroup,
  fetchRelationshipGroups,
  saveExistingRelationshipGroupOptimisticStore,
} from 'stores/actions/relationships';
import { createNewSetting, saveSetting, deleteExistingSetting } from 'stores/actions/settings';
import { createStoryboard, deleteStoryboard, saveStoryboard } from 'stores/actions/storyboards';
import {
  createNewTimeline,
  deleteExistingTimeline,
  fetchTimeline,
  fetchTimelines,
  saveExistingTimeline,
  saveExistingTimelineOptimisticStore,
} from 'stores/actions/timelines';

export interface ErrorState {
  error: { message: string };
}

const initialState: ErrorState = {
  error: { message: '' },
};

const errorHelper = (error: SerializedError, backupMessage = 'An error occured saving your data.') => {
  return { message: error.message ?? backupMessage };
};

export const errorReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(
    isAnyOf(
      saveExistingTimeline.rejected,
      deleteExistingTimeline.rejected,
      fetchTimelines.rejected,
      fetchTimeline.rejected,
      createNewTimeline.rejected,
      deleteExistingTimeline.rejected,
      saveExistingTimelineOptimisticStore.rejected,
      fetchRelationshipGroups.rejected,
      saveExistingRelationshipGroupOptimisticStore.rejected,
      deleteExistingRelationshipGroup.rejected,
      createNewRelationshipGroup.rejected,
      saveExistingRelationshipGroupOptimisticStore.rejected,
      fetchRelationshipGroup.rejected,
      fetchChapters.rejected,
      saveChapter.rejected,
      saveStoryboard.rejected,
      deleteStoryboard.rejected,
      createStoryboard.rejected,
      createNewSetting.rejected,
      saveSetting.rejected,
      deleteExistingSetting.rejected,
      createNewCharacter.rejected,
      deleteExistingCharacter.rejected,
      saveCharacter.rejected,
      fetchCharacters.rejected,
      createNewArtifact.rejected,
      saveArtifact.rejected,
      deleteExistingArtifact.rejected,
    ),
    (state, action) => {
      return {
        ...state,
        error: errorHelper(action.error, `Error saving data`),
      };
    },
  );
});
