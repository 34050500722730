export enum Collections {
  PROJECTS = 'Projects',
  CHARACTERS = 'Characters',
  ARTIFACTS = 'Artifacts',
  USER = 'Users',
  TIMELINES = 'Timelines',
  RELATIONSHIPS = 'Relationships',
  CHAPTERS = 'Chapters',
  SETTINGS = 'Settings',
  STORYBOARDS = 'Storyboards',
  CUSTOMERS = 'customers',
}
