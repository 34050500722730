import 'firebase/auth';

import React, { Suspense, lazy, useEffect } from 'react';
import { retrieveUserQuickWinAccomplished, retrieveUserSubscriptions } from '../../../stores/actions/user';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from '../../common/loading';
import { Login } from '../../login';
import firebase from 'firebase/app';
import styles from './index.module.scss';
// import PricingPlans from 'components/public/pricing-plans';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from '../../common/AuthHook/useAuthentication';
import { selectCurrentUser } from 'stores/selectors/user';

const Projects = lazy(() => import('../projects'));

const renderApp = (loading: boolean, user: firebase.User | undefined) => {
  const redirect = new URLSearchParams(location.search).get('redirect');
  if (loading) {
    return (
      <div className={styles.App}>
        <div className={styles.loading}>
          <Loading />
        </div>
      </div>
    );
  }

  if (redirect === 'pricing') {
    <Navigate to="/pricing" />;
  }

  return user ? (
    <Suspense
      fallback={
        <div className={styles.loading}>
          <Loading />
        </div>
      }
    >
      <Projects user={user} />
      {/* {redirect === 'pricing' ? <PricingPlans /> : <Projects user={user} />} */}
    </Suspense>
  ) : (
    <Login />
  );
};

export const EntryPoint = () => {
  const dispatch = useDispatch();
  const _user = useSelector(selectCurrentUser);
  const { loading } = useAuthentication();

  useEffect(() => {
    if (_user) {
      // dispatch(setUserActivated(_user));
      dispatch(retrieveUserSubscriptions(_user));
      dispatch(retrieveUserQuickWinAccomplished(_user));
    }
  }, [_user]);

  return <div className={styles.Projects}>{renderApp(loading, _user)}</div>;
};
