import 'firebase/firestore';

import { Collections } from 'models/enums/collections';
import firebase from 'firebase/app';
import firebaseConfig from '../constants';

// PATH CONSTRUCTORS
export const basePath = Collections.USER;
export const getProjectsPath = (userId: string) => `${basePath}/${userId}/${Collections.PROJECTS}`;
export const getCharactersPath = (userId: string, projectId: string) =>
  `${getProjectsPath(userId)}/${projectId}/${Collections.CHARACTERS}`;
export const getSettingsPath = (userId: string, projectId: string) =>
  `${getProjectsPath(userId)}/${projectId}/${Collections.SETTINGS}`;
export const getArtifactsPath = (userId: string, projectId: string) =>
  `${getProjectsPath(userId)}/${projectId}/${Collections.ARTIFACTS}`;
export const getTimelinesPath = (userId: string, projectId: string) =>
  `${getProjectsPath(userId)}/${projectId}/${Collections.TIMELINES}`;
export const getRelationshipsPath = (userId: string, projectId: string) =>
  `${getProjectsPath(userId)}/${projectId}/${Collections.RELATIONSHIPS}`;
export const getChaptersPath = (userId: string, projectId: string) =>
  `${getProjectsPath(userId)}/${projectId}/${Collections.CHAPTERS}`;
export const getStoryboardsPath = (userId: string, projectId: string) =>
  `${getProjectsPath(userId)}/${projectId}/${Collections.CHAPTERS}`;

// INITIALIZE FIREBASE
export const firebaseInitDB = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  return firebase.firestore();
};

// CRUD OPERATIONS FOR DOCUMENTS
export async function firebaseCreateDocument<T extends { id?: string }>(path: string, document: T): Promise<T> {
  const db = firebaseInitDB();
  await db.collection(path).doc(document.id).set(document);
  return document;
}

export function firebaseDocumentRequest<T>(path: string, docId: string): Promise<T> {
  const db = firebaseInitDB();
  return db
    .collection(path)
    .doc(docId)
    .get()
    .then((doc) => doc.data() as T);
}

export async function firebaseUpdateDocument<T extends { id?: string }>(path: string, document: T): Promise<T> {
  const db = firebaseInitDB();
  await db.collection(path).doc(document.id).update(document);
  return document;
}

export async function firebaseDeleteDocument(path: string, documentId: string): Promise<string> {
  const db = firebaseInitDB();
  await db.collection(path).doc(documentId).delete();
  return documentId;
}

export async function firebasePartialUpdateDocument(
  path: string,
  documentId: string,
  field: string,
  value: any,
): Promise<{ documentId: string; field: string; value: any }> {
  const db = firebaseInitDB();
  const docRef = db.collection(path).doc(documentId);
  await docRef.update({
    [field]: value,
  });
  return { documentId, field, value };
}

// READ COLLECTIONS
export function firebaseCollectionRequest<T>(path: string): Promise<T[]> {
  const db = firebaseInitDB();
  return db
    .collection(path)
    .get()
    .then((querysnapshot) => {
      const data: firebase.firestore.DocumentData[] = [];
      querysnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return (data as unknown) as T[];
    });
}
