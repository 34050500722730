import { Button, Tag } from 'antd';
import { Card, Flippable, RotationType, Size } from '@draftcraft/cards';
import React, { FC, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-markdown';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';

export const FlippableCardExample: FC = () => {
  const [flipped, setFlipped] = useState<boolean>(false);
  const [flippedVertical, setFlippedVertical] = useState<boolean>(false);

  const table = `
  | Props   | Type     | Required |
  |---------|----------| -------- |
  | flipped    | boolean   | Yes      |
  | direction  | RotationType, 'Vertical', or 'Horizontal'   | Yes      |
  `;

  return (
    <div className={styles.FlippableCardExample}>
      <h2 className={styles.h2}>Flippable</h2>
      <p>
        Flippable is a component that can flip content from one side to the other. It takes children but only renders
        the first two children given to it. The first child will render on the front of the flippable content and the
        second child will render on the back. Any content can be passed to flippable as a child. Below are examples of
        flippable Cards - the first is setup to flip horizontally and the second setup to flip vertically.
      </p>
      <div className={styles.center}>
        <Markdown source={table} />
      </div>
      <div className={styles.example}>
        <div>
          <strong>TS</strong>
          <pre>
            {String.raw`import { Flippable, RotationType } from '@draftcraft/cards';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Example = (props) => {
  const [flipped, setFlipped] = useState<boolean>(false);

  return (
    <Flippable flipped={flipped} direction={RotationType.HORIZONTAL}>
      <ExampleCardFront flip={() => setFlipped(true)} />
      <ExampleCardBack flip={() => setFlipped(false)} />
    </Flippable>
)};
`}
          </pre>
        </div>
        <Flippable flipped={flipped} direction={RotationType.HORIZONTAL}>
          <Card
            size={Size.LARGE}
            shadow
            rounded
            imgUrl={'https://upload.wikimedia.org/wikipedia/en/f/f1/Silk-comic_cover.jpg'}
            imgAlt={'Silk Superhero'}
            className={styles.card__image}
          >
            <div className={styles.card__title}>
              <h3>Silk</h3>
            </div>
            <div className={styles.card__content}>
              Cindy Moon is a fictional Korean American superhero, known as Silk, appearing in American comic books
              published by Marvel Comics.
            </div>
            <div className={styles.button}>
              <Button type="link" className={styles.button} onClick={() => setFlipped(true)}>
                Flip
              </Button>
            </div>
          </Card>
          <Card size={Size.LARGE} shadow rounded className={styles.card__image}>
            <div className={styles.card__content}>
              <h2 className={styles.h2}>Strength</h2>
              {Array(10)
                .fill(undefined)
                .map((_, i) => (
                  <FontAwesomeIcon icon={faStar} key={i} />
                ))}
            </div>
            <div className={styles.card__content}>
              <h2 className={styles.h2}>Powers</h2>
              <Tag color="red">Accelerated Healing</Tag>
              <Tag color="red">Agility</Tag>
              <Tag color="red">Danger Sense</Tag>
              <Tag color="red">Super Strength</Tag>
              <Tag color="red">Web Crawling</Tag>
            </div>
            <div className={styles.card__content}>
              Silk possesses the proportionate powers of a spider, granted to her from an irradiated Common House Spider
              (Achaearanea tepidariorum) which bit Cindy Moon that was apparently already mutated from prior exposure to
              certain frequencies of radiation and received a final, lethal dose during Moon's attendance of the
              exhibition.
            </div>
            <div className={styles.button}>
              <Button type="link" className={styles.button} onClick={() => setFlipped(false)}>
                Flip
              </Button>
            </div>
          </Card>
        </Flippable>
      </div>
      <div className={styles.example}>
        <div>
          <strong>TS</strong>
          <pre>
            {String.raw`import { Flippable, RotationType } from '@draftcraft/cards';
import React, { useState } from 'react';

export const Example = (props) => {
  const [flipped, setFlipped] = useState<boolean>(false);

  return (
    <Flippable flipped={flipped} direction={RotationType.Vertical}>
      <ExampleCardFront flip={() => setFlipped(true)} />
      <ExampleCardBack flip={() => setFlipped(false)} />
    </Flippable>
)};
`}
          </pre>
        </div>
        <Flippable flipped={flippedVertical} direction={RotationType.VERTICAL}>
          <Card
            size={Size.LARGE}
            shadow
            rounded
            imgUrl={'https://upload.wikimedia.org/wikipedia/en/f/f1/Silk-comic_cover.jpg'}
            imgAlt={'Silk Superhero'}
            className={styles.card__image}
          >
            <div className={styles.card__title}>
              <h3>Silk</h3>
            </div>
            <div className={styles.card__content}>
              Cindy Moon is a fictional Korean American superhero, known as Silk, appearing in American comic books
              published by Marvel Comics.
            </div>
            <div className={styles.button}>
              <Button type="link" className={styles.button} onClick={() => setFlippedVertical(true)}>
                Flip
              </Button>
            </div>
          </Card>
          <Card size={Size.LARGE} shadow rounded className={styles.card__image}>
            <div className={styles.card__content}>
              <h2 className={styles.h2}>Strength</h2>
              {Array(10)
                .fill(undefined)
                .map((_, i) => (
                  <FontAwesomeIcon icon={faStar} key={i} />
                ))}
            </div>
            <div className={styles.card__content}>
              <h2 className={styles.h2}>Powers</h2>
              <Tag color="red">Accelerated Healing</Tag>
              <Tag color="red">Agility</Tag>
              <Tag color="red">Danger Sense</Tag>
              <Tag color="red">Super Strength</Tag>
              <Tag color="red">Web Crawling</Tag>
            </div>
            <div className={styles.card__content}>
              Silk possesses the proportionate powers of a spider, granted to her from an irradiated Common House Spider
              (Achaearanea tepidariorum) which bit Cindy Moon that was apparently already mutated from prior exposure to
              certain frequencies of radiation and received a final, lethal dose during Moon's attendance of the
              exhibition.
            </div>
            <div className={styles.button}>
              <Button type="link" className={styles.button} onClick={() => setFlippedVertical(false)}>
                Flip
              </Button>
            </div>
          </Card>
        </Flippable>
      </div>
    </div>
  );
};
