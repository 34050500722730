import {
  createNewCharacter,
  deleteExistingCharacter,
  fetchCharacters,
  setCharacter,
  setCharacters,
} from '../actions/characters';

import { Character } from '../../models/interfaces/character';
import { createReducer } from '@reduxjs/toolkit';
import { saveCharacter } from '../actions/characters';

export interface State {
  loading: boolean;
  characters: Partial<Character>[];
}

export const initialState: State = {
  loading: false,
  characters: [],
};

export const charactersReducer = createReducer(initialState, (builder) => {
  builder.addCase(setCharacters, (state, { payload }) => ({
    ...state,
    characters: payload.characters,
  }));
  builder.addCase(setCharacter, (state, { payload }) => ({
    ...state,
    characters: state.characters.map((char) => (char.id === payload.id ? payload : char)),
  }));
  builder.addCase(createNewCharacter.fulfilled, (state, { payload }) => ({
    ...state,
    characters: [payload, ...state.characters],
  }));
  builder.addCase(deleteExistingCharacter.fulfilled, (state, { payload }) => ({
    ...state,
    characters: state.characters.filter((character) => character.id !== payload),
  }));
  builder.addCase(saveCharacter.fulfilled, (state, { payload }) => ({
    ...state,
    loading: false,
    characters: state.characters.map((char) => (char.id !== payload.id ? char : payload)),
  }));
  builder.addCase(saveCharacter.pending, (state) => ({
    ...state,
    loading: true,
  }));
  builder.addCase(saveCharacter.rejected, (state) => ({
    ...state,
    loading: false,
  }));
  builder.addCase(fetchCharacters.pending, (state) => ({
    ...state,
    loading: true,
  }));
  builder.addCase(fetchCharacters.rejected, (state) => ({
    ...state,
    loading: false,
  }));
  builder.addCase(fetchCharacters.fulfilled, (state, { payload }) => ({
    ...state,
    loading: false,
    characters: payload,
  }));
});
