import { createSlice } from '@reduxjs/toolkit';
import { LineTypes } from 'models/enums/line-types';
import { defaultReactFlowJsonObject, RelationshipGroup } from 'models/interfaces/relationships';
import { ReactFlowJsonObject } from 'react-flow-renderer';

import {
  createNewRelationshipGroup,
  deleteEdge,
  deleteExistingRelationshipGroup,
  fetchRelationshipGroup,
  fetchRelationshipGroups,
  saveExistingRelationshipGroupOptimisticStore,
  updateEdgeColor,
  updateEdgeLabel,
  updateEdgeType,
  updateRelationshipFlow,
  updateRelationshipGroupIds,
} from 'stores/actions/relationships';

export interface RelationshipsState {
  relationshipGroups: Partial<RelationshipGroup>[];
  loading: boolean;
}

export const initialState: RelationshipsState = {
  relationshipGroups: [],
  loading: false,
};

const deleteEdgeFromFlow = (edgeId: string, flow: ReactFlowJsonObject<any, any> = defaultReactFlowJsonObject) => {
  return {
    ...flow,
    edges: flow.edges.filter((edge) => edge.id !== edgeId),
  };
};

export const relationshipSlice = createSlice({
  name: 'relationships',
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(updateRelationshipGroupIds, (state, { payload }) => {
      return {
        ...state,
        relationshipGroups: state.relationshipGroups.map((relationshipGroup) => {
          return relationshipGroup.id !== payload.relationshipGroupId
            ? relationshipGroup
            : {
                ...relationshipGroup,
                [payload.attribute]: payload.value,
              };
        }),
      };
    });
    builder.addCase(updateRelationshipFlow, (state, { payload }) => {
      return {
        ...state,
        relationshipGroups: state.relationshipGroups.map((relationshipGroup) => {
          return relationshipGroup.id !== payload.relationshipGroupId
            ? relationshipGroup
            : {
                ...relationshipGroup,
                flow: payload.flow,
              };
        }),
      };
    });
    builder.addCase(updateEdgeType, (state, { payload }) => {
      return {
        ...state,
        relationshipGroups: state.relationshipGroups.map((relationshipGroup) => {
          return relationshipGroup.id !== payload.relationshipGroupId
            ? relationshipGroup
            : {
                ...relationshipGroup,
                flow: {
                  ...(relationshipGroup.flow || defaultReactFlowJsonObject),
                  edges: (relationshipGroup.flow || defaultReactFlowJsonObject).edges.map((edge) => {
                    if (edge.id === payload.edge?.id || '') {
                      return {
                        ...edge,
                        style: {
                          ...edge.style,
                          strokeDasharray: payload.type === LineTypes.DASHED ? '10, 10' : '0',
                        },
                      };
                    }
                    return edge;
                  }),
                },
              };
        }),
      };
    });
    builder.addCase(updateEdgeColor, (state, { payload }) => {
      return {
        ...state,
        relationshipGroups: state.relationshipGroups.map((relationshipGroup) => {
          return relationshipGroup.id !== payload.relationshipGroupId
            ? relationshipGroup
            : {
                ...relationshipGroup,
                flow: {
                  ...(relationshipGroup.flow || defaultReactFlowJsonObject),
                  edges: (relationshipGroup.flow || defaultReactFlowJsonObject).edges.map((edge) => {
                    if (edge.id === payload.edge?.id || '') {
                      return {
                        ...edge,
                        style: { ...edge.style, stroke: payload.color },
                      };
                    }
                    return edge;
                  }),
                },
              };
        }),
      };
    });
    builder.addCase(updateEdgeLabel, (state, { payload }) => {
      console.log('UPDATE!');
      return {
        ...state,
        relationshipGroups: state.relationshipGroups.map((relationshipGroup) => {
          return relationshipGroup.id !== payload.relationshipGroupId
            ? relationshipGroup
            : {
                ...relationshipGroup,
                flow: {
                  ...(relationshipGroup.flow || defaultReactFlowJsonObject),
                  edges: (relationshipGroup.flow || defaultReactFlowJsonObject).edges.map((edge) => {
                    if (edge.id === payload.edgeId || '') {
                      return {
                        ...edge,
                        label: payload.label,
                      };
                    }
                    return edge;
                  }),
                },
              };
        }),
      };
    });
    builder.addCase(deleteEdge, (state, { payload }) => {
      return {
        ...state,
        relationshipGroups: state.relationshipGroups.map((relationshipGroup) => {
          return relationshipGroup.id !== payload.relationshipGroupId
            ? relationshipGroup
            : {
                ...relationshipGroup,
                flow: deleteEdgeFromFlow(payload.edgeId, relationshipGroup.flow),
              };
        }),
      };
    });
    builder.addCase(fetchRelationshipGroups.pending, (state) => ({ ...state, loading: true }));
    builder.addCase(fetchRelationshipGroups.rejected, (state) => ({ ...state, loading: false }));
    builder.addCase(fetchRelationshipGroups.fulfilled, (state, { payload }) => {
      return { ...state, loading: false, relationshipGroups: payload };
    });
    builder.addCase(fetchRelationshipGroup.pending, (state) => ({ ...state, loading: true }));
    builder.addCase(fetchRelationshipGroup.rejected, (state) => ({ ...state, loading: false }));
    builder.addCase(fetchRelationshipGroup.fulfilled, (state, { payload }) => {
      return { ...state, loading: false, relationshipGroups: [...state.relationshipGroups, payload] };
    });
    builder.addCase(createNewRelationshipGroup.pending, (state) => ({ ...state, loading: true }));
    builder.addCase(createNewRelationshipGroup.rejected, (state) => ({ ...state, loading: false }));
    builder.addCase(createNewRelationshipGroup.fulfilled, (state, { payload }) => {
      return { ...state, loading: false, relationshipGroups: [...state.relationshipGroups, payload] };
    });
    builder.addCase(deleteExistingRelationshipGroup.pending, (state) => ({ ...state, loading: true }));
    builder.addCase(deleteExistingRelationshipGroup.rejected, (state) => ({ ...state, loading: false }));
    builder.addCase(deleteExistingRelationshipGroup.fulfilled, (state, { payload: deletedId }) => {
      const updatedRelationshipGroup = state.relationshipGroups.filter(
        (relationshipGroup) => relationshipGroup.id !== deletedId,
      );
      return { ...state, loading: false, relationshipGroups: updatedRelationshipGroup };
    });
    builder.addCase(saveExistingRelationshipGroupOptimisticStore.fulfilled, (state, { payload }) => {
      const updatedRelationshipGroup = state.relationshipGroups.map((relationshipGroup) =>
        relationshipGroup.id === payload.id ? payload : relationshipGroup,
      );
      return { ...state, loading: false, relationshipGroups: updatedRelationshipGroup };
    });
    builder.addCase(saveExistingRelationshipGroupOptimisticStore.pending, (state, action) => {
      const updatingRelationshipGroup = action.meta.arg;
      const updatedRelationshipGroup = state.relationshipGroups.map((relationshipGroup) =>
        relationshipGroup.id === updatingRelationshipGroup.id ? updatingRelationshipGroup : relationshipGroup,
      );
      return { ...state, loading: true, relationshipGroups: updatedRelationshipGroup };
    });
    builder.addCase(saveExistingRelationshipGroupOptimisticStore.rejected, (state) => ({ ...state, loading: false }));
  },
});

export const relationshipsReducer = relationshipSlice.reducer;
