import { createAsyncThunk } from '@reduxjs/toolkit';
import { Errors } from 'models/enums/error';
import { Setting } from 'models/interfaces/setting';
import {
  firebaseCollectionRequest,
  firebaseCreateDocument,
  firebaseDeleteDocument,
  firebaseUpdateDocument,
  getArtifactsPath,
} from 'services/database-service';
import { getProjectId } from './characters';
import { getUserId } from './projects';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { Artifact } from 'models/interfaces/artifact';
import moment from 'moment';

//Async Action Creators
/**
 * Fetch all settings from the Settings collection
 */
export const fetchArtifacts = createAsyncThunk<Setting[], string>('FETCH_Artifacts', (projectId, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined) {
    return firebaseCollectionRequest<Setting>(getArtifactsPath(userId, projectId));
  } else {
    throw Errors.USER_OR_PROJECT_UNDEFINED;
  }
});

/**
 * Create a new Artifact
 */
export const createNewArtifact = createAsyncThunk<Partial<Artifact>, Partial<Artifact>>(
  'CREATE_NEW_ARTIFACT',
  (setting: Partial<Artifact>, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    const projectId = getProjectId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined) {
      firebase.analytics().logEvent('create_content', { content_type: 'Artifacts' });
      return firebaseCreateDocument<Partial<Artifact>>(getArtifactsPath(userId, projectId), setting);
    } else {
      throw Errors.USER_OR_PROJECT_UNDEFINED;
    }
  },
);

/**
 * Update an existing Artifact
 */
export const saveArtifact = createAsyncThunk('SAVE_ARTIFACT', (artifact: Partial<Artifact>, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  const projectId = getProjectId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined) {
    return firebaseUpdateDocument(getArtifactsPath(userId, projectId), { ...artifact, updated: moment.now() });
  } else {
    throw Errors.USER_OR_PROJECT_UNDEFINED;
  }
});

/**
 * Delete an existing Artifact
 */
export const deleteExistingArtifact = createAsyncThunk('DELETE_ARTIFACT', (artifact: Partial<Artifact>, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  const projectId = getProjectId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined && artifact.id !== undefined) {
    return firebaseDeleteDocument(getArtifactsPath(userId, projectId), artifact.id);
  } else {
    throw Errors.USER_PROJECT_OR_ASSET_UNDEFINED;
  }
});
