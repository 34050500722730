import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  basePath,
  firebaseCollectionRequest,
  firebaseCreateDocument,
  firebaseDeleteDocument,
  firebasePartialUpdateDocument,
  firebaseUpdateDocument,
  getCharactersPath,
} from '../../services/database-service';

import { Character } from '../../models/interfaces/character';
import { Errors } from 'models/enums/error';
import { RootState } from '../congifure-store';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { getUserId } from './projects';
import { selectCurrentProject } from 'stores/selectors/projects';
import moment from 'moment';
import axios from 'axios';

// Helpers
export const getProjectId = (ThunkAPI: any) => {
  const state: RootState = ThunkAPI.getState() as RootState;
  return selectCurrentProject(state);
};

// Action Creators
export const setCharacters = createAction<{ characters: Character[] }>('SET_CHARACTERS');
export const setCharacter = createAction<Partial<Character>>('SET_CHARACTER');

//Async Action Creators
export const createNewCharacter = createAsyncThunk<Partial<Character>, Partial<Character>>(
  'CREATE_NEW_CHARACTER',
  (character: Partial<Character>, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    const projectId = getProjectId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined) {
      firebase.analytics().logEvent('create_content', { content_type: 'character' });
      return firebaseCreateDocument<Partial<Character>>(getCharactersPath(userId, projectId), character);
    } else {
      throw Errors.USER_OR_PROJECT_UNDEFINED;
    }
  },
);

export const deleteExistingCharacter = createAsyncThunk(
  'DELETE_CHARACTER',
  (character: Partial<Character>, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    const projectId = getProjectId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined && character.id !== undefined) {
      return firebaseDeleteDocument(getCharactersPath(userId, projectId), character.id);
    } else {
      throw Errors.USER_PROJECT_OR_ASSET_UNDEFINED;
    }
  },
);

export const saveCharacter = createAsyncThunk('SAVE_CHARACTER', (character: Partial<Character>, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  const projectId = getProjectId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined) {
    return firebaseUpdateDocument(getCharactersPath(userId, projectId), { ...character, updated: moment.now() });
  } else {
    throw Errors.USER_OR_PROJECT_UNDEFINED;
  }
});

export const fetchCharacters = createAsyncThunk<Character[], string>('FETCH_CHARACTERS', (projectId, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined) {
    return firebaseCollectionRequest<Character>(getCharactersPath(userId, projectId));
  } else {
    throw Errors.USER_UNDEFINED;
  }
});

export const finishCharacterOnboarding = createAsyncThunk('FINISH_CHARACTER_ONBOARDING', async (_, ThunkAPI) => {
  const state: RootState = ThunkAPI.getState() as RootState;
  const user = state.User.user;
  return firebasePartialUpdateDocument(basePath, user?.uid ?? '', 'quickWinAccomplished', true)
    .catch(() =>
      firebaseCreateDocument(basePath, {
        id: user?.uid ?? '',
        quickWinAccomplished: true,
      }),
    )
    .finally(() => {
      return axios
        .post(
          `https://us-central1-draft-craft.cloudfunctions.net/user/sendgrid`,
          {
            email: user?.email,
            customFields: {
              w3_T: 'true', // quickWinAccomplished
            },
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-type': 'application/json',
            },
          },
        )
        .catch(console.error);
    });
});
