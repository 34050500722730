import { Button } from 'antd';
import React, { useState, useEffect } from 'react';

import styles from './index.module.scss';

export const WordPlugin = () => {
  const width = window.innerWidth;
  const [frameWidth, setFrameWidth] = useState(width > 600 ? 560 : 336);
  const [frameHeight, setFrameHeight] = useState(width > 600 ? 315 : 189);

  const resizeCallback = () => {
    const width = window.innerWidth;
    setFrameHeight(width > 1000 ? 551 : width > 600 ? 315 : 189);
    setFrameWidth(width > 1000 ? 980 : width > 600 ? 560 : 336);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  return (
    <div className={styles.WordPlugin}>
      <div className={styles.mainContainer}>
        <div>
          <div className={styles.partnerText}>Fiction is an official partner of</div>
          <div>
            <img src={'./Microsoft-logo.png'} alt="Microsoft" className={styles.msLogo} />
          </div>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.intro}>
            <span>Introducing the</span> <span className={styles.red}>&nbsp;Fiction Word Plugin</span>
            <span>.</span>
          </div>
          <img src="./Word+Fiction.png" alt="Fiction Word Plugin" className={styles.pluginLogo} />
        </div>
      </div>
      <div className={styles.video}>
        <iframe
          width={frameWidth}
          height={frameHeight}
          src="https://www.youtube.com/embed/sSi_iPaIjCo"
          title="Fiction Software - Fiction Word Plugin"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles.actionContainer}>
        <a href="https://appsource.microsoft.com/en-us/product/office/WA200004722?tab=Overview" target="_blank">
          <Button type="primary" shape="round" size="large" className={styles.install}>
            Install Today
          </Button>
        </a>
        <a href="https://help.fictionsoftware.com/word-plugin" target="_blank">
          <Button type="ghost" shape="round" size="large">
            Learn More
          </Button>
        </a>
      </div>
    </div>
  );
};
