import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import { setUser } from 'stores/actions/user';
import firebaseConfig from '../../../constants';
import { fetchProjects } from 'stores/actions/projects';

export const useAuthentication = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const _setUser = useCallback((user: firebase.User | undefined) => dispatch(setUser({ user })), [dispatch]);
  const authStateChangeCallback = useCallback((user) => {
    if (user) {
      _setUser(JSON.parse(JSON.stringify(user)));
      dispatch(fetchProjects(user?.uid));
    } else {
      console.warn('No authentication detected');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.credential) {
          /** @type {firebase.auth.OAuthCredential} */
          // const credential = result.credential;
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const token = credential.accessToken;
          // ...
        }
        // The signed-in user info.
        // const user = result.user;
        console.warn('OAuth handshake successful.');
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // const credential = error.credential;
        // ...
        console.warn({ errorCode, errorMessage, email });
      });
    firebase.auth().onAuthStateChanged(authStateChangeCallback);
  }, []);

  return {
    loading,
  };
};
