import { Card, Deck, Size } from '@draftcraft/cards';
import React, { FC, useState } from 'react';

import Markdown from 'react-markdown';
import styles from './index.module.scss';

export const DeckExample: FC = () => {
  const [message, setMessage] = useState<string>('');

  const table1 = `
  | Props   | Type     | Required |
  |---------|----------| -------- |
  | swipeable   | boolean   | No       |
  | onSwipeLeft  | () => void   | No       |
  | onSwipeRight  | () => void   | No       |
`;
  const randomRange = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  return (
    <div className={styles.DeckExample}>
      <h2 className={styles.title}>Deck</h2>
      <p>
        A deck is stacked colection of cards. The deck component takes an array of children that it will display stacked
        on top of each other. If swipeable is set to true the cards can be swiped left or right by the user. Optionally
        you can also define a function to be called each time a card is swiped a certain direction. As shown here, you
        can transform each card slightly in the deck to give it a more realistic look.
      </p>
      <div className={styles.tables}>
        <Markdown source={table1} />
      </div>
      <div className={styles.example}>
        <div>
          <strong>TS</strong>
          <pre>
            {String.raw`import { Deck, Card, Size } from '@draftcraft/cards';
import useState from 'react';

const randomRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
}

export const Example = (props) => {
  const [message, setMessage] = useState<string>('');

  return (
  <Deck
    swipeable
    onSwipeLeft={() => { setMessage('You swiped left!') }}
    onSwipeRight={() => { setMessage('You swiped right!') }}
  >
      <Card
        size={Size.LARGE}
        shadow
        rounded
        imgUrl={
          'http://img3.wikia.nocookie.net/__cb20070814082157/
          marveldatabase/images/b/b4/New_Avengers_Vol_1_15_Textless.jpg'
        }
        imgAlt={'Spider Woman'}
        className={styles.card__image}
        style={{ transform: \`rotate(${randomRange(-1 * 4, 4)}deg)\`, userSelect: 'none' }}
      >
        <div className={styles.card__title3}>
          <h3>Spider Woman</h3>
        </div>
        <div className={styles.card__content}>
          Spider-Woman (Jessica Drew) is a fictional superheroine appearing in American
          comic books published by Marvel Comics.
        </div>
      </Card>
      <Card
        size={Size.LARGE}
        shadow
        rounded
        imgUrl={
          'https://static.tvtropes.org/pmwiki/pub/images/
          spider_gwen_vol_1_1_anka_variant_textless.jpg'
        }
        imgAlt={'Spider Gwen'}
        className={styles.card__image}
        style={{ transform: \`rotate(${randomRange(-1 * 4, 4)}deg)\`, userSelect: 'none' }}
      >
        <div className={styles.card__title2}>
          <h3>Spider Gwen</h3>
        </div>
        <div className={styles.card__content}>
        Spider-Gwen is a comic book series about Gwen Stacy of Earth-65,
        an alternate universe version of Gwen Stacy as part of the 2014–2015 Spider-Man
        storyline "Spider-Verse".
        </div>
      </Card>
      <Card
        size={Size.LARGE}
        shadow
        rounded
        imgUrl={'https://upload.wikimedia.org/wikipedia/en/f/f1/Silk-comic_cover.jpg'}
        imgAlt={'Silk Superhero'}
        className={styles.card__image}
        style={{ transform: \`rotate(${randomRange(-1 * 4, 4)}deg)\`, userSelect: 'none' }}
      >
        <div className={styles.card__title}>
          <h3>Silk</h3>
        </div>
        <div className={styles.card__content}>
          Cindy Moon is a fictional Korean American superhero, known as Silk,
          appearing in American comic books published by Marvel Comics.
        </div>
      </Card>
  </Deck>
)};`}
          </pre>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Deck
            swipeable
            onSwipeLeft={() => {
              setMessage('You swiped left!');
            }}
            onSwipeRight={() => {
              setMessage('You swiped right!');
            }}
          >
            <Card
              size={Size.LARGE}
              shadow
              rounded
              imgUrl={
                'http://img3.wikia.nocookie.net/__cb20070814082157/marveldatabase/images/b/b4/New_Avengers_Vol_1_15_Textless.jpg'
              }
              imgAlt={'Spider Woman'}
              className={styles.card__image}
              style={{ transform: `rotate(${randomRange(-1 * 4, 4)}deg)`, userSelect: 'none' }}
            >
              <div className={styles.card__title3}>
                <h3>Spider Woman</h3>
              </div>
              <div className={styles.card__content}>
                Spider-Woman (Jessica Drew) is a fictional superheroine appearing in American comic books published by
                Marvel Comics.
              </div>
            </Card>
            <Card
              size={Size.LARGE}
              shadow
              rounded
              imgUrl={'https://static.tvtropes.org/pmwiki/pub/images/spider_gwen_vol_1_1_anka_variant_textless.jpg'}
              imgAlt={'Spider Gwen'}
              className={styles.card__image}
              style={{ transform: `rotate(${randomRange(-1 * 4, 4)}deg)`, userSelect: 'none' }}
            >
              <div className={styles.card__title2}>
                <h3>Spider Gwen</h3>
              </div>
              <div className={styles.card__content}>
                Spider-Gwen is a comic book series about Gwen Stacy of Earth-65, an alternate universe version of Gwen
                Stacy as part of the 2014–2015 Spider-Man storyline "Spider-Verse".
              </div>
            </Card>
            <Card
              size={Size.LARGE}
              shadow
              rounded
              imgUrl={'https://upload.wikimedia.org/wikipedia/en/f/f1/Silk-comic_cover.jpg'}
              imgAlt={'Silk Superhero'}
              className={styles.card__image}
              style={{ transform: `rotate(${randomRange(-1 * 4, 4)}deg)`, userSelect: 'none' }}
            >
              <div className={styles.card__title}>
                <h3>Silk</h3>
              </div>
              <div className={styles.card__content}>
                Cindy Moon is a fictional Korean American superhero, known as Silk, appearing in American comic books
                published by Marvel Comics.
              </div>
            </Card>
          </Deck>
          <br />
          {message}
        </div>
      </div>
    </div>
  );
};
