import {
  addProjectToList,
  createProject,
  deleteProject,
  fetchProjects,
  setCurrentProject,
  setCurrentTab,
  setProjects,
  updateProjectAttribute,
} from '../actions/projects';

import { Project } from '../../models/interfaces/project';
import { createReducer } from '@reduxjs/toolkit';

export interface State {
  projects: Partial<Project>[];
  loading: boolean;
  current: string;
  currentProject?: string;
}

const initialState: State = {
  projects: [],
  loading: false,
  current: 'Analytics',
};

export const projectsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setProjects, (state, { payload }) => ({
    ...state,
    projects: payload.projects,
  }));
  builder.addCase(setCurrentTab, (state, { payload }) => ({
    ...state,
    current: payload.current,
  }));
  builder.addCase(setCurrentProject, (state, { payload }) => ({
    ...state,
    currentProject: payload.currentProject,
  }));
  builder.addCase(addProjectToList, (state, { payload }) => ({
    ...state,
    projects: [payload, ...state.projects],
  }));
  builder.addCase(updateProjectAttribute, (state, { payload }) => ({
    ...state,
    projects: state.projects.map((project) =>
      project.id !== payload.projectId
        ? project
        : {
            ...project,
            [payload.attribute]: payload.value,
          },
    ),
  }));
  builder.addCase(deleteProject.fulfilled, (state, { payload }) => ({
    ...state,
    projects: state.projects.filter((project) => project.id !== payload),
  }));
  builder.addCase(fetchProjects.pending, (state) => ({
    ...state,
    loading: true,
  }));
  builder.addCase(fetchProjects.rejected, (state) => ({
    ...state,
    loading: false,
    projects: [],
  }));
  builder.addCase(fetchProjects.fulfilled, (state, { payload }) => ({
    ...state,
    loading: false,
    projects: payload,
  }));
  builder.addCase(createProject.pending, (state) => ({
    ...state,
    loading: true,
  }));
  builder.addCase(createProject.rejected, (state) => ({
    ...state,
    loading: false,
  }));
  builder.addCase(createProject.fulfilled, (state, { payload }) => ({
    ...state,
    loading: false,
    projects: [payload, ...state.projects],
    currentProject: payload.id,
  }));
});
