import { ChapterTag } from 'models/enums/chapter-tag';
export interface Chapter {
  id: string;
  title: string;
  description: string;
  tags: string | ChapterTag;
  created: number;
  updated: number;
  settings: string[];
  characters: string[];
  artifacts: string[];
}

export const CHAPTER_ID = 'CHAPTER_CONST_ID';
