import React, { FC } from 'react';

import { ActionDetails } from '@draftcraft/cards';
import Markdown from 'react-markdown';
import styles from './index.module.scss';

export const ActionDetailsExample: FC = () => {
  const table1 = `
  | Props   | Type     | Required |
  |---------|----------| -------- |
  | title   | string   | No       |
  | description   | string   | Yes       |
  | shape   | Shape   | No       |
  | hoverable  | boolean   | No       |
  | hoverColor  | String   | No       |
  `;

  return (
    <div className={styles.ActionDetailsExample}>
      <h2 className={styles.title}>Action Details</h2>
      <p>
        The Action Details component is a call to action with additional details surrounding it. It takes all the same
        props as the Action component but adds a title and a description prop in addition. The title is optional but the
        description is required.
      </p>
      <div className={styles.tables}>
        <Markdown source={table1} />
      </div>
      <div className={styles.example}>
        <div>
          <strong>TS</strong>
          <pre>
            {String.raw`import { Action, Shape, ActionDetails } from '@draftcraft/cards';

export const Example = (props) => (
  <ActionDetails
    title={'Attachment'}
    description={'Click here to add an attachment to this message'}
    hoverColor={'#ff4b4b'}
    hoverable
  >
    <h2 style={{ color: '#ff4b4b' }}>
      &#x1f4ce;
    </h2>
  </ActionDetails>
);`}
          </pre>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ActionDetails
            title={'Attachment'}
            description={'Click here to add an attachment to this message'}
            className={styles.left}
            hoverColor={'#ff4b4b'}
            hoverable
          >
            <h2 style={{ color: '#ff4b4b' }}>&#x1f4ce;</h2>
          </ActionDetails>
        </div>
      </div>
    </div>
  );
};
