import React, { FC, useState, useEffect } from 'react';

import styles from './index.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const DemoVideo: FC<Props> = () => {
  const width = window.innerWidth;
  const [frameWidth, setFrameWidth] = useState(width > 1000 ? 980 : width > 600 ? 560 : 336);
  const [frameHeight, setFrameHeight] = useState(width > 1000 ? 551 : width > 600 ? 315 : 189);

  const resizeCallback = () => {
    const width = window.innerWidth;
    setFrameHeight(width > 1000 ? 551 : width > 600 ? 315 : 189);
    setFrameWidth(width > 1000 ? 980 : width > 600 ? 560 : 336);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  return (
    <div className={styles.DemoVideo}>
      <div className={styles.container}>
        {/* <div> */}
        <span className={styles.hero__red}>Product</span>
        {/* </div> */}
        <div>
          <span className={styles.hero}>Demo</span>
          <span className={styles.hero__red}>.</span>
        </div>
      </div>
      <iframe
        width={frameWidth}
        height={frameHeight}
        src="https://www.youtube.com/embed/NZk2Wtjr8cU"
        title="Fiction Software - Product Demo Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};
