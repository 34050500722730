import React, { FC, useEffect } from 'react';

import { Action } from '@draftcraft/cards';
import { ActionDetailsExample } from '../action-details-example';
import { ActionExample } from '../action-example';
import { BasicCardExample } from '../basic-card-example';
import { Button } from 'antd';
import { CardCarouselExample } from '../card-carousel-example';
import { DeckExample } from '../deck-example';
import { FlippableCardExample } from '../flippable-card-example';
import { Helmet } from 'react-helmet';
import firebase from 'firebase/app';
import 'firebase/analytics';
import firebaseConfig from '../../../../constants';
import styles from './index.module.scss';

export const Cards: FC = () => {
  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    firebase.analytics().logEvent('page_view', { page_title: 'React Cards by Draftcraft', page_location: '/cards' });
  }, []);

  return (
    <div className={styles.Cards}>
      <Helmet>
        <title>React Cards by Fiction</title>
        <meta name="description" content="An open source, comprehensive, card-based, React component library" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className={styles.head}>
        <div className={styles.mask}>
          <h1>Cards</h1>
          <h2>
            A comprehensive, card-based, React component library by
            <a href="https://www.fictionsoftware.com">
              <img src={'./Fiction-Red-Bookmark_clearbg.png'} alt="Fiction" className={styles.logo} />
            </a>
            Fiction
          </h2>
          <div className={styles.cta}>
            <a href="https://github.com/draftcraft/cards">
              <Button size="large" shape="round" type="primary" ghost>
                Contribute
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.main}>
        <p className={styles.desc}>
          The design system of Fiction relies heavily on card based components. <br />
          As such the core elements have been abstracted for open source use and contribution under a standard MIT
          license.
        </p>
        <div className={styles.main__content}>
          <Action className={styles.card__custom}>
            <strong className={styles.card__title}>Installation</strong>
            With NPM
            <pre>npm install --save @draftcraft/cards</pre>
            <br />
            ...or with Yarn
            <pre>yarn add @draftcraft/cards</pre>
            <br />
            Load the style sheet in your project
            <pre>import "@draftcraft/cards/dist/index.css";</pre>
          </Action>
          <Action className={styles.card__custom}>
            <strong className={styles.card__title}>Basic Usage</strong>
            Import the component or types (if using Typescript) that you wish to use. A comprehensive list of
            components, their props, and examples can be seen below.
            <pre>
              {String.raw`import { Card, Size } from '@draftcraft/cards';
import { Draftcraft } from '../../draftcraft/index';

export const Example = (props) => (
  <Card size={Size.LARGE}>
    {props.content}
  </Card>
);`}
            </pre>
          </Action>
        </div>
        <div className={styles.main__content__examples}>
          <BasicCardExample />
          <FlippableCardExample />
          <ActionExample />
          <ActionDetailsExample />
          <DeckExample />
          <CardCarouselExample />
        </div>
      </div>
    </div>
  );
};
