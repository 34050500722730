import {
  ApartmentOutlined,
  AppstoreOutlined,
  LineChartOutlined,
  PictureOutlined,
  ProfileOutlined,
  ProjectOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { ActionDetails } from '@draftcraft/cards';
import { Button } from 'antd';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styles from './index.module.scss';

export const FeatureList = () => {
  return (
    <div className={styles.FeatureList}>
      <div className={styles.container}>
        <div className={styles.assertion}>
          <h2>All the tools you need to keep your creative juices flowing.</h2>
        </div>
        <div className={styles.illustration}>
          <img src="./creative_working.svg" alt="Creative Writing" />
        </div>
        <div className={styles.split}>
          <div className={styles.split__left}>
            <ScrollAnimation animateIn="fadeInLeft">
              <ActionDetails
                title="Chapter Outlining"
                description="Plan your entire story before writing a single chapter. Reorganize, add, remove, and adjust on the fly."
              >
                <ProfileOutlined className={styles.icon} />
              </ActionDetails>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft">
              <ActionDetails
                title="Character Builder"
                description="Scrutinize your characters in painstaking detail to ensure they are as well rounded as possible."
              >
                <UserOutlined className={styles.icon} />
              </ActionDetails>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft">
              <ActionDetails
                title="Artifact Builder"
                description="Build detailed descriptions of the physical objects that are important to your story."
              >
                <ToolOutlined className={styles.icon} />
              </ActionDetails>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft">
              <ActionDetails
                title="Setting Builder"
                description="Create all of the places that your characters will visit throughout the course of your story."
              >
                <PictureOutlined className={styles.icon} />
              </ActionDetails>
            </ScrollAnimation>
          </div>
          <div className={styles.split__right}>
            <ScrollAnimation animateIn="fadeInRight">
              <ActionDetails
                title="Timelines"
                description="Track concurrent events in chronological timelines to ensure consistency in your stories."
              >
                <ProjectOutlined className={styles.icon} />
              </ActionDetails>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInRight">
              <ActionDetails
                title="Storyboarding"
                description="Expand on events by storyboarding them in a series of sequential actions."
              >
                <AppstoreOutlined className={styles.icon} />
              </ActionDetails>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInRight">
              <ActionDetails
                title="Relationship Hierarchies"
                description="Keep track of complicated relationships amongst your depthy characters."
              >
                <ApartmentOutlined className={styles.icon} />
              </ActionDetails>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInRight">
              <ActionDetails
                title="Analytics"
                description="Keep track of what you're working on with a comprehensive dashboard."
              >
                <LineChartOutlined className={styles.icon} />
              </ActionDetails>
            </ScrollAnimation>
          </div>
        </div>
        <a href="/projects">
          <Button shape="round" size="large" type="primary" className={styles.button}>
            Let's get started!
          </Button>
        </a>
      </div>
    </div>
  );
};
