import { charactersReducer } from './reducers/characters';
import { configureStore } from '@reduxjs/toolkit';
import { projectsReducer } from './reducers/projects';
import { userReducer } from './reducers/user';
import { chaptersReducer } from './reducers/chapters';
import { timelinesReducer } from './reducers/timelines';
import { settingsReducer } from './reducers/settings';
import { artifactsReducer } from './reducers/artifacts';
import { errorReducer } from './reducers/errors';
import { storyboardsReducer } from 'stores/reducers/storyboards';
import { relationshipsReducer } from './reducers/relationships';
import { relationshipLineOptionsReducer } from './reducers/relationshipLineOptions';

const store = configureStore({
  reducer: {
    Projects: projectsReducer,
    User: userReducer,
    Characters: charactersReducer,
    Chapters: chaptersReducer,
    Timelines: timelinesReducer,
    Relationships: relationshipsReducer,
    RelationshipLineOptions: relationshipLineOptionsReducer,
    Settings: settingsReducer,
    Artifacts: artifactsReducer,
    Errors: errorReducer,
    Storyboards: storyboardsReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
