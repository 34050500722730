import { createSelector } from '@reduxjs/toolkit';
import { ChronologyTypes } from 'models/enums/chronology';
import { GranularityTypes } from 'models/enums/granularity';
import { EventModel, EventWithDayNumbers } from 'models/interfaces/event';
import { isTimelineSetting, TimelineEvent, TimelineSetting } from 'models/interfaces/timeline';
import { TimelineLabel } from 'models/interfaces/timelineLabel';
import moment from 'moment';
import { RootState } from 'stores/congifure-store';

export const selectTimelines = (state: RootState) => state.Timelines.timelines;
export const selectColumnWidth = (id?: string) => (state: RootState) =>
  state.Timelines.timelines.find((timeline) => timeline.id === id)?.columnWidth ?? 100;
export const selectChronology = (id: string) => (state: RootState) =>
  state.Timelines.timelines.find((timeline) => timeline.id === id)?.chronology ?? ChronologyTypes.ABSOLUTE;

export const selectGranularity = (id: string) => (state: RootState) =>
  state.Timelines.timelines.find((timeline) => timeline.id === id)?.granularity ?? GranularityTypes.DAY;

export const selectTimeline = (id: string) => (state: RootState) =>
  state.Timelines.timelines.find((timeline) => timeline.id === id);

export const selecteTimelineSettingIds = (id: string) =>
  createSelector(selectTimeline(id), (timeline) => {
    return timeline?.settings?.filter((setting) => isTimelineSetting(setting)).map((setting) => setting.id);
  });

// Helpers for selecting TimelineEvents
/* Takes in an array of events and returns an array of events with the start and end dates calculated
based on the time since the first start date. This is for use in the display grid to know where to start. */
export const calculateEventsWithDayNumbers = (events: Partial<EventModel>[], firstDate: number) =>
  events.map((event) => {
    const newEvent = { ...event, startDateNumber: 0, endDateNumber: 0 };
    newEvent.startDateNumber = calculateDaysBetweenDates(firstDate, event?.startDate ?? moment.now());
    newEvent.endDateNumber = calculateDaysBetweenDates(firstDate, event?.endDate ?? moment.now());
    return newEvent;
  });

export const calculateDaysBetweenDates = (firstDate: number, secondDate: number) => {
  const timeSinceFirstEvent = moment.unix(secondDate).diff(moment.unix(firstDate), 'day', true);
  return Math.round(timeSinceFirstEvent);
};

export const calculateMinStartDate = (events: Partial<EventWithDayNumbers>[]) => {
  const startDates = events.map((event) => event.startDate ?? 0);
  return Math.min(...startDates);
};

export const calculateMaxEndDate = (events: Partial<EventWithDayNumbers>[]) => {
  const endDates = events.map((event) => event.endDate ?? 0);
  return Math.max(...endDates);
};

// Selectors to get TimelineEvents and Timeline Span
const selectTimelineSettings = (timelineId: string) => (state: RootState) => {
  const timeline = state.Timelines.timelines.find((timeline) => timeline.id === timelineId);
  return timeline?.settings ?? ([] as (TimelineSetting | TimelineLabel)[]);
};

// Skip this heavy calculations if selectTimelineSettings returns same value
const selectTimelineEventsAndDates = (timelineId: string) =>
  createSelector(selectTimelineSettings(timelineId), (settings) => {
    const flattenedEvents =
      settings?.reduce((acc: Partial<EventModel>[], setting: TimelineSetting | TimelineLabel) => {
        if (isTimelineSetting(setting)) {
          return [...acc, ...(setting?.events ?? [])];
        }
        return acc;
      }, [] as Partial<EventModel>[]) ?? ([] as EventModel[]);

    const firstStartDate = calculateMinStartDate(flattenedEvents);
    const lastEndDate = calculateMaxEndDate(flattenedEvents);

    const newTimelineEvents: TimelineEvent = {};
    settings.forEach((setting) => {
      if (isTimelineSetting(setting)) {
        newTimelineEvents[setting.id] = calculateEventsWithDayNumbers(setting?.events ?? [], firstStartDate)
          // sort by start date number for proper order in CSS grid
          .sort((a, b) => a.startDateNumber - b.endDateNumber);
      }
    });

    return { newTimelineEvents, firstStartDate, lastEndDate };
  });

export const selectSettingEvents = (timelineId: string, settingId: string) =>
  createSelector(selectTimelineEventsAndDates(timelineId), ({ newTimelineEvents }) => {
    return newTimelineEvents[settingId];
  });

export const selectEvent = (timelineId: string, settingId: string, eventId: string) => (state: RootState) => {
  const timelineSetting = selectTimelineSettings(timelineId)(state).find((setting) => setting.id === settingId);

  return timelineSetting && isTimelineSetting(timelineSetting)
    ? (timelineSetting?.events ?? []).find((event) => event.id === eventId)
    : undefined;
};

export const selectTimelineSpan = (timelineId: string) =>
  createSelector(selectTimelineEventsAndDates(timelineId), ({ firstStartDate, lastEndDate }) => {
    return calculateDaysBetweenDates(firstStartDate, lastEndDate) + 1;
  });

export const selectFirstStartDate = (timelineId: string) =>
  createSelector(selectTimelineEventsAndDates(timelineId), ({ firstStartDate }) => {
    return firstStartDate;
  });
