import { basePath, firebaseCollectionRequest, firebaseDocumentRequest } from 'services/database-service';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import firebase from 'firebase/app';
import 'firebase/auth';
import { Collections } from 'models/enums/collections';
import { Errors } from '../../models/enums/error';
import { Subscription } from 'models/interfaces/user';

export const setUser = createAction<{ user?: firebase.User }>('SET_USER');

export const setUserActivated = createAsyncThunk<
  { owner: string; activated: boolean },
  firebase.User | null | undefined
>('SET_USER_ACTIVATED', async (user?: firebase.User | null) => {
  return firebaseDocumentRequest<{ owner: string; activated: boolean }>(basePath, user?.uid ?? '');
});

export const retrieveUserSubscriptions = createAsyncThunk<Subscription[], firebase.User | null | undefined>(
  'SET_USER_SUBSCRIPTIONS',
  async (user?: firebase.User | null) => {
    if (user?.uid !== undefined) {
      const resp = await firebaseCollectionRequest<Subscription>(`${Collections.CUSTOMERS}/${user?.uid}/subscriptions`);
      return JSON.parse(JSON.stringify(resp));
    } else {
      throw Errors.USER_UNDEFINED;
    }
  },
);

export const retrieveUserQuickWinAccomplished = createAsyncThunk<
  { owner: string; quickWinAccomplished: boolean },
  firebase.User | null | undefined
>('SET_USER_QUICK_WIN_ACCOMPLISHED', async (user?: firebase.User | null) => {
  return firebaseDocumentRequest<{ owner: string; quickWinAccomplished: boolean }>(basePath, user?.uid ?? '');
});
