import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  firebaseCollectionRequest,
  firebaseCreateDocument,
  firebaseDeleteDocument,
  firebaseDocumentRequest,
  firebaseUpdateDocument,
  getTimelinesPath,
} from '../../services/database-service';

import { Errors } from 'models/enums/error';
import { RootState } from '../congifure-store';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { getUserId } from './projects';
import { TimelineModel, TimelineSetting } from 'models/interfaces/timeline';
import { getProjectId } from './characters';
import { selectTimeline } from 'stores/selectors/timelines';
import { TimelineLabel } from 'models/interfaces/timelineLabel';
import { EventModel } from 'models/interfaces/event';
import moment from 'moment';
import { GranularityTypes } from '../../models/enums/granularity';

// Helpers
export const getTimeline = (ThunkAPI: any, timelineId: string) => {
  const state: RootState = ThunkAPI.getState() as RootState;
  return selectTimeline(timelineId)(state);
};

const saveExistingTimelineBase = (actionName = 'timelines/saveTimeline') =>
  createAsyncThunk<Partial<TimelineModel>, Partial<TimelineModel>>(actionName, (timeline, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    const projectId = getProjectId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined) {
      return firebaseUpdateDocument(getTimelinesPath(userId, projectId), { ...timeline, updated: moment.now() });
    } else {
      throw Errors.USER_OR_PROJECT_UNDEFINED;
    }
  });

// Action Creators
export const addSettings = createAction<{ timelineId: string; settingIds: string[] }>('timelines/addSettings');
export const setColumnWidth = createAction<{ columnWidth: number; timelineId: string }>('timelines/setColumnWidth');
// export const setChronology = createAction<ChronologyTypes>('timelines/setChronology');
export const addLabel = createAction<{ timelineId: string; label: TimelineLabel }>('timelines/addLabel');
export const deleteSettingOrLabel = createAction<{ timelineId: string; labelOrSettingId: string }>(
  'timelines/deleteSettingOrLabel',
);
export const deleteSettingsOrLabels = createAction<{ timelineId: string; labelOrSettingIds: string[] }>(
  'timelines/deleteSettingsOrLabels',
);
export const setSettings = createAction<{ timelineId: string; settings: (TimelineSetting | TimelineLabel)[] }>(
  'timelines/setSettings',
);
export const setLabelTitle = createAction<{
  timelineId: string;
  labelId: string;
  newName: string;
}>('timelines/set');
export const addEventToSetting = createAction<{ timelineId: string; settingId: string; newEvent: Partial<EventModel> }>(
  'timelines/addEventToSetting',
);
export const deleteEventFromSetting = createAction<{
  timelineId: string;
  settingId: string;
  eventId: string;
}>('timelines/deleteEventFromSetting');
export const updateEventOnSetting = createAction<{
  timelineId: string;
  settingId: string;
  newEvent: Partial<EventModel>;
}>('timelines/updateEventOnSetting');

export const updateGranularity = createAction<{ timelineId: string; granularity: GranularityTypes }>(
  'timelines/updateGranularity',
);

//Async Action Creators
export const createNewTimeline = createAsyncThunk<Partial<TimelineModel>, Partial<TimelineModel>>(
  'timelines/createTimeline',
  (timeline: Partial<TimelineModel>, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    const projectId = getProjectId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined) {
      firebase.analytics().logEvent('create_content', { content_type: 'timeline' });
      return firebaseCreateDocument<Partial<TimelineModel>>(getTimelinesPath(userId, projectId), timeline);
    } else {
      throw Errors.USER_OR_PROJECT_UNDEFINED;
    }
  },
);

export const deleteExistingTimeline = createAsyncThunk('timelines/deleteTimeline', (timelineId: string, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  const projectId = getProjectId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined && timelineId !== undefined) {
    return firebaseDeleteDocument(getTimelinesPath(userId, projectId), timelineId);
  } else {
    throw Errors.USER_PROJECT_OR_ASSET_UNDEFINED;
  }
});

export const saveExistingTimeline = saveExistingTimelineBase('timelines/saveTimeline/FirebaseOnly');

export const saveExistingTimelineOptimisticStore = saveExistingTimelineBase('timelines/saveTimeline/LocalAndFirebase');

export const fetchTimelines = createAsyncThunk<Partial<TimelineModel>[], string>(
  'timelines/fetchTimelines',
  (projectId, ThunkAPI) => {
    const userId = getUserId(ThunkAPI) ?? '';
    return firebaseCollectionRequest<Partial<TimelineModel>>(getTimelinesPath(userId, projectId));
  },
);

export const fetchTimeline = createAsyncThunk<Partial<TimelineModel>, { projectId: string; timelineId: string }>(
  'timelines/fetchTimeline',
  ({ projectId, timelineId }, ThunkAPI) => {
    const userId = getUserId(ThunkAPI) ?? '';
    return firebaseDocumentRequest<Partial<TimelineModel>>(getTimelinesPath(userId, projectId), timelineId);
  },
);
