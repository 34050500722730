const firebaseConfig = {
  apiKey: 'AIzaSyCbf-gQkmFnVTub24fOGvQWBbS-P7l4gGc',
  // authDomain: 'draft-craft.firebaseapp.com',
  authDomain: 'fictionsoftware.com',
  databaseURL: 'https://draft-craft.firebaseio.com',
  projectId: 'draft-craft',
  storageBucket: 'draft-craft.appspot.com',
  messagingSenderId: '806218557235',
  appId: '1:806218557235:web:fb6334856a770a99cc0046',
  measurementId: 'G-BR288VX1HR',
};

export default firebaseConfig;
