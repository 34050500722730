import moment from 'moment';
import { ReactFlowJsonObject } from 'react-flow-renderer';
import { v4 } from 'uuid';

export const defaultReactFlowJsonObject = {
  edges: [],
  nodes: [],
  viewport: {
    x: 0,
    y: 0,
    zoom: 0.5,
  },
} as ReactFlowJsonObject<any, any>;

export interface RelationshipGroup {
  id: string;
  name: string;
  description: string;
  characterIds: string[];
  settingIds: string[];
  artifactIds: string[];
  created: number;
  updated: number;
  flow: ReactFlowJsonObject<any, any>;
}

export function createRelationshipFromPartial(relationshipGroup: Partial<RelationshipGroup>): RelationshipGroup {
  return {
    id: relationshipGroup.id || v4(),
    name: relationshipGroup.name || '',
    description: relationshipGroup.description || '',
    created: relationshipGroup.created || moment.now(),
    updated: relationshipGroup.updated || moment.now(),
    characterIds: relationshipGroup.characterIds || [],
    settingIds: relationshipGroup.settingIds || [],
    artifactIds: relationshipGroup.artifactIds || [],
    flow: relationshipGroup.flow || defaultReactFlowJsonObject,
  };
}
