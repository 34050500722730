import { createNewChapter, setEditing, setChapters, fetchChapters, saveChapter } from '../actions/chapters';

import { Chapter } from '../../models/interfaces/chapter';
import { createReducer } from '@reduxjs/toolkit';

export interface State {
  loading: boolean;
  chapters: Partial<Chapter>[];
  editing?: string;
}

const initialState: State = {
  loading: false,
  chapters: [],
};

export const chaptersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createNewChapter.pending, (state) => ({
      ...state,
      loading: true,
    }))
    .addCase(createNewChapter.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      chapters: payload.chapters,
      editing: payload.editing,
    }))
    .addCase(setEditing, (state, { payload }) => ({
      ...state,
      editing: payload,
    }))
    .addCase(setChapters, (state, { payload }) => ({
      ...state,
      chapters: payload,
    }))
    .addCase(fetchChapters.pending, (state) => ({
      ...state,
      loading: true,
    }))
    .addCase(fetchChapters.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      chapters: payload?.chapters ?? [],
    }))
    .addCase(saveChapter.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      editing: payload.editing,
      chapters: payload.chapters,
    }))
    .addCase(saveChapter.pending, (state, { meta }) => ({
      ...state,
      loading: true,
      editing: meta.arg.editing,
      chapters: meta.arg.chapters,
    }));
});
