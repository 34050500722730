import React, { useEffect } from 'react';

import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FeatureList } from '../feature-list';
import { Footer } from '../footer';
import firebase from 'firebase/app';
import 'firebase/analytics';
import firebaseConfig from '../../../constants';
import { Quote } from '../Quote';
import cx from 'classnames';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { DemoVideo } from '../demo-video';
import { WordPlugin } from '../word-plugin';

export const Welcome = () => {
  const getSize = window.screen.width < 520 ? 'small' : 'large';

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    firebase.analytics().logEvent('page_view', { page_title: 'home', page_location: '/' });
  }, []);

  return (
    <div className={styles.Welcome}>
      <div className={styles.container__header}>
        <div className={styles.container}>
          <img src="/FictionAI_red.png" alt="Fiction Software" className={styles.logo} />
        </div>
        <div className={styles.container}>
          <Link to="pricing">
            <Button size={getSize} shape="round" className={styles.__button}>
              Pricing
            </Button>
          </Link>
          <a href="https://feedback.fictionsoftware.com/">
            <Button size={getSize} shape="round" className={styles.__button}>
              Feedback
            </Button>
          </a>
          <Link to="/projects?login=true">
            <Button type="primary" size={getSize} shape="round" className={styles.__button}>
              Login
            </Button>
          </Link>
        </div>
      </div>
      <div className={styles.container__center}>
        <div className={styles.banner}>
          <div className={styles.banner__left}>
            <h2>
              <span className={styles.spaced__text}>Web-based</span>
              <span className={cx(styles.hero__red, styles.block)}>Story</span>
              <span className={cx(styles.hero, styles.block)}>
                Planning<span className={styles.hero__red}>.</span>
              </span>
            </h2>
            <h3 className={styles.button__start}>We are pleased to welcome authors to our public beta.</h3>
            <a href="/projects">
              <Button type="primary" size="large" shape="round" className={styles.button__start}>
                Get Started Free
              </Button>
            </a>
          </div>
          <div className={styles.banner__right}>
            <img src="./computer.png" alt="Fiction Software" className={styles.computer} />
          </div>
        </div>
        <div className={styles.center}>
          <div>Features</div>
          <DownOutlined />
        </div>
      </div>
      <WordPlugin />
      <Quote />
      <FeatureList />
      <DemoVideo />
      <Footer />
    </div>
  );
};
