import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  firebaseCollectionRequest,
  firebaseCreateDocument,
  firebaseDeleteDocument,
  firebaseUpdateDocument,
  getProjectsPath,
} from 'services/database-service';

import { Errors } from 'models/enums/error';
import { Project } from '../../models/interfaces/project';
import { RootState } from 'stores/congifure-store';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { selectUserId } from 'stores/selectors/user';

// Helper
export const getUserId = (ThunkAPI: any) => {
  const state: RootState = ThunkAPI.getState() as RootState;
  return selectUserId(state);
};

// Action Creators
export const setProjects = createAction<{ projects: Partial<Project>[] }>('SET_PROJECTS');
export const setCurrentTab = createAction<{ current: string }>('SET_CURRENT_TAB');
export const setCurrentProject = createAction<{ currentProject: string | undefined }>('SET_CURRENT_PROJECT');
export const updateProjectAttribute = createAction<{ projectId: string; attribute: string; value: string }>(
  'UPDATE_PROJECT_ATTRIBUTE',
);
export const addProjectToList = createAction<Project>('ADD_PROJECT');

// Async Action Creators
export const fetchProjects = createAsyncThunk('FETCH_PROJECTS', (userId: string) =>
  firebaseCollectionRequest<Partial<Project>>(getProjectsPath(userId)),
);

export const createProject = createAsyncThunk<Project, Project>('CREATE_PROJECT', (project: Project, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  firebase.analytics().logEvent('create_content', { content_type: 'project' });
  if (userId !== undefined) {
    return firebaseCreateDocument(getProjectsPath(userId), project);
  } else {
    throw Errors.USER_UNDEFINED;
  }
});

export const updateProject = createAsyncThunk<Partial<Project>, Partial<Project>>(
  'PERSIST_PROJECT_UPDATE',
  (project, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    if (userId !== undefined) {
      return firebaseUpdateDocument<Partial<Project>>(getProjectsPath(userId), project);
    } else {
      throw Errors.USER_UNDEFINED;
    }
  },
);

export const deleteProject = createAsyncThunk('DELETE_PROJECT', (projectId: string, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  if (userId !== undefined) {
    return firebaseDeleteDocument(getProjectsPath(userId), projectId);
  } else {
    throw Errors.USER_UNDEFINED;
  }
});
