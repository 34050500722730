import { ChronologyTypes } from 'models/enums/chronology';
import { GranularityTypes } from 'models/enums/granularity';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { EventModel, EventWithDayNumbers } from './event';
import { TimelineLabel } from './timelineLabel';

export interface TimelineModel {
  id: string;
  title: string;
  description: string;
  created: number;
  updated: number;
  settings: (TimelineSetting | TimelineLabel)[];
  granularity: GranularityTypes;
  columnWidth: number;
  chronology?: ChronologyTypes;
}
export interface TimelineEvent {
  [id: string]: Partial<EventWithDayNumbers>[];
}
export interface TimelineSetting {
  type: 'setting';
  id: string;
  events?: Partial<EventModel>[];
}

export function isTimelineSetting(settingOrLabel: TimelineSetting | TimelineLabel): settingOrLabel is TimelineSetting {
  return (settingOrLabel as TimelineSetting).type === 'setting';
}

export function createTimelineFromPartial(timeline: Partial<TimelineModel>): TimelineModel {
  return {
    id: timeline.id || uuidv4(),
    title: timeline.title || '',
    description: timeline.description || '',
    created: timeline.created || moment.now(),
    updated: timeline.updated || moment.now(),
    settings: timeline.settings || [],
    granularity: timeline.granularity || GranularityTypes.DAY,
    columnWidth: timeline.columnWidth || 100,
    chronology: timeline.chronology,
  };
}
