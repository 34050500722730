import { Card, CardCarousel, Size } from '@draftcraft/cards';
import React, { FC } from 'react';

import Markdown from 'react-markdown';
import styles from './index.module.scss';

export const CardCarouselExample: FC = () => {
  const table = `
  | Props   | Type     | Required |
  |---------|----------| -------- |
  | cardSize   | Size   | Yes       |
  | spacing  | number   | Yes       |
  | showPagination  | boolean   | No       |
  | showArrows  | boolean   | No       |
  `;

  const cards = [
    <Card
      size={Size.LARGE}
      shadow
      rounded
      imgUrl={
        'http://img3.wikia.nocookie.net/__cb20070814082157/marveldatabase/images/b/b4/New_Avengers_Vol_1_15_Textless.jpg'
      }
      imgAlt={'Spider Woman'}
      className={styles.card__image}
    >
      <div className={styles.card__title3}>
        <h3>Spider Woman</h3>
      </div>
      <div className={styles.card__content}>
        Spider-Woman (Jessica Drew) is a fictional superheroine appearing in American comic books published by Marvel
        Comics.
      </div>
    </Card>,
    <Card
      size={Size.LARGE}
      shadow
      rounded
      imgUrl={'https://static.tvtropes.org/pmwiki/pub/images/spider_gwen_vol_1_1_anka_variant_textless.jpg'}
      imgAlt={'Spider Gwen'}
      className={styles.card__image}
    >
      <div className={styles.card__title2}>
        <h3>Spider Gwen</h3>
      </div>
      <div className={styles.card__content}>
        Spider-Gwen is a comic book series about Gwen Stacy of Earth-65, an alternate universe version of Gwen Stacy as
        part of the 2014–2015 Spider-Man storyline "Spider-Verse".
      </div>
    </Card>,
    <Card
      size={Size.LARGE}
      shadow
      rounded
      imgUrl={'https://upload.wikimedia.org/wikipedia/en/f/f1/Silk-comic_cover.jpg'}
      imgAlt={'Silk Superhero'}
      className={styles.card__image}
    >
      <div className={styles.card__title}>
        <h3>Silk</h3>
      </div>
      <div className={styles.card__content}>
        Cindy Moon is a fictional Korean American superhero, known as Silk, appearing in American comic books published
        by Marvel Comics.
      </div>
    </Card>,
  ];

  return (
    <div className={styles.CardCarouselExample}>
      <h2 className={styles.title}>Card Carousel</h2>
      <p>
        The Card Carousel component takes a collection of cards as children and allows you to swipe through them. It
        will automatically calculate the number of cards per page based on the screen width and the provided values
        surrounding the card size and margins.
      </p>
      <div className={styles.tables}>
        <Markdown source={table} />
      </div>
      <div>
        <div>
          <strong>TS</strong>
          <pre>
            {String.raw`import { CardCarousel, Size, Card } from '@draftcraft/cards';

export const Example = (props) => (
  <CardCarousel
    cardSize={Size.LARGE}
    spacing={10}
    showPagination
    showArrows
  >
    {[...cardChildren]}
  </CardCarousel>
);`}
          </pre>
        </div>
        <div>
          <CardCarousel cardSize={Size.LARGE} spacing={10} showPagination showArrows>
            {[...cards, ...cards, ...cards, ...cards]}
          </CardCarousel>
        </div>
      </div>
    </div>
  );
};
