import { Project } from 'models/interfaces/project';
import { RootState } from 'stores/congifure-store';

export const selectProjects = (state: RootState) => state.Projects.projects;

export const selectProject = (state: RootState, id?: string) =>
  state.Projects.projects.find((project: Partial<Project>) => project?.id === id);

export const selectProjectsLoading = (state: RootState) => state.Projects.loading;

export const selectCurrentProject = (state: RootState) => state.Projects.currentProject;
