import React, { FC } from 'react';

import styles from './index.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const Privacy: FC<Props> = () => {
  return (
    <div className={styles.Privacy}>
      <h1>Privacy Policy</h1>
      <p>
        Protecting your private information is our priority. This Statement of Privacy applies to
        www.fictionsoftware.com, and Fiction Software LLC and governs data collection and usage. For the purposes of
        this Privacy Policy, unless otherwise noted, all references to Fiction Software LLC include
        www.fictionsoftware.com and Fiction. The Fiction website is a authoring tools site. By using the Fiction
        website, you consent to the data practices described in this statement.
      </p>
      <p className={styles.bold}>Collection of your Personal Information </p>
      <p>
        In order to better provide you with products and services offered, Fiction may collect personally identifiable
        information, such as your email address. We do not collect any personal information about you unless you
        voluntarily provide it to us. However, you may be required to provide certain personal information to us when
        you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a
        sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected
        third parties; (d) sending us an email message; (e) submitting your credit card or other payment information
        when ordering and purchasing products and services. To wit, we will use your information for, but not limited
        to, communicating with you in relation to services and/or products you have requested from us. We also may
        gather additional personal or non-personal information in the future.
      </p>
      <p className={styles.bold}>Use of your Personal Information</p>
      <p>
        Fiction collects and uses your personal information to operate and deliver the services you have requested.
        Fiction may also use your personally identifiable information to inform you of other products or services
        available from Fiction and its affiliates.
      </p>
      <p className={styles.bold}>Sharing Information with Third Parties</p>
      <p>
        Fiction does not sell, rent or lease its customer lists to third parties. Fiction may, from time to time,
        contact you on behalf of external business partners about a particular offering that may be of interest to you.
        In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not
        transferred to the third party. Fiction may share data with trusted partners to help perform statistical
        analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third
        parties are prohibited from using your personal information except to provide these services to Fiction, and
        they are required to maintain the confidentiality of your information. Fiction may disclose your personal
        information, without notice, if required to do so by law or in the good faith belief that such action is
        necessary to: (a) conform to the edicts of the law or comply with legal process served on Fiction or the site;
        (b) protect and defend the rights or property of Fiction; and/or (c) act under exigent circumstances to protect
        the personal safety of users of Fiction, or the public.
      </p>
      <p className={styles.bold}>Tracking User Behavior</p>
      <p>
        Fiction may keep track of the websites and pages our users visit within Fiction, in order to determine what
        Fiction services are the most popular. This data is used to deliver customized content and advertising within
        Fiction to customers whose behavior indicates that they are interested in a particular subject area.
      </p>
      <p className={styles.bold}>Automatically Collected Information</p>
      <p>
        Information about your computer hardware and software may be automatically collected by Fiction. This
        information can include: your IP address, browser type, domain names, access times and referring website
        addresses. This information is used for the operation of the service, to maintain quality of the service, and to
        provide general statistics regarding use of the Fiction website.
      </p>
      <p className={styles.bold}>Use of Cookies</p>
      <p>
        The Fiction website may use "cookies" to help you personalize your online experience. A cookie is a text file
        that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses
        to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that
        issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you
        time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example,
        if you personalize Fiction pages, or register with Fiction site or services, a cookie helps Fiction to recall
        your specific information on subsequent visits. This simplifies the process of recording your personal
        information, such as billing addresses, shipping addresses, and so on. When you return to the same Fiction
        website, the information you previously provided can be retrieved, so you can easily use the Fiction features
        that you customized. You have the ability to accept or decline cookies. Most Web browsers automatically accept
        cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to
        decline cookies, you may not be able to fully experience the interactive features of the Fiction services or
        websites you visit.
      </p>
      <p className={styles.bold}>Links</p>
      <p>
        This website contains links to other sites. Please be aware that we are not responsible for the content or
        privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read
        the privacy statements of any other site that collects personally identifiable information.
      </p>
      <p className={styles.bold}>Security of your Personal Information</p>
      <p>
        Fiction secures your personal information from unauthorized access, use, or disclosure. Fiction uses SSL
        Protocol for this purpose. When personal information (such as a credit card number) is transmitted to other
        websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. We
        strive to take appropriate security measures to protect against unauthorized access to or alteration of your
        personal information. Unfortunately, no data transmission over the Internet or any wireless network can be
        guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge
        that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and
        (b) security, integrity, and privacy of any and all information and data exchanged between you and us through
        this Site cannot be guaranteed.
      </p>
      <p className={styles.bold}>Right to Deletion</p>
      Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
      <ul>
        <li>Delete your personal information from our records; and</li>
        <li>Direct any service providers to delete your personal information from their records.</li>
      </ul>
      Please note that we may not be able to comply with requests to delete your personal information if it is necessary
      to:
      <ul>
        <li>
          Complete the transaction for which the personal information was collected, fulfill the terms of a written
          warranty or product recall conducted in accordance with federal law, provide a good or service requested by
          you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise
          perform a contract between you and us;
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute
          those responsible for that activity;
        </li>
        <li>Debug to identify and repair errors that impair existing intended functionality;</li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or
          exercise another right provided for by law;
        </li>
        <li>Comply with the California Electronic Communications Privacy Act;</li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that
          adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to
          render impossible or seriously impair the achievement of such research, provided we have obtained your
          informed consent;
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with
          us;
        </li>
        <li>Comply with an existing legal obligation; or</li>
        <li>
          Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in
          which you provided the information.
        </li>
      </ul>
      <p className={styles.bold}>Children Under Thirteen</p>
      <p>
        Fiction does not knowingly collect personally identifiable information from children under the age of thirteen.
        If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.
      </p>
      <p className={styles.bold}>E-mail Communications</p>
      <p>
        From time to time, Fiction may contact you via email for the purpose of providing announcements, promotional
        offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we
        may receive a notification when you open an email from Fiction or click on a link therein. If you would like to
        stop receiving marketing or promotional communications via email from Fiction, you may opt out of such
        communications by clicking the unsubscribe button.
      </p>
      <p className={styles.bold}>External Data Storage Sites</p>
      <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>
      <p className={styles.bold}>Changes to this Statement</p>
      <p>
        Fiction reserves the right to change this Privacy Policy from time to time. We will notify you about significant
        changes in the way we treat personal information by sending a notice to the primary email address specified in
        your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your
        continued use of the website and/or Services available after such modifications will constitute your: (a)
        acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
      </p>
      <p className={styles.bold}>Contact Information</p>
      <p>
        Fiction welcomes your questions or comments regarding this Statement of Privacy. If you believe that Fiction has
        not adhered to this Statement, please contact Fiction at:
      </p>
      <p>Fiction Software LLC</p> <p>948 East Deer Arch Lane Draper,</p>
      <p>Utah 84020</p>
      <p>Email Address: info@fictionsoftware.com</p>
      <p>Effective as of January 01, 2022</p>
    </div>
  );
};
