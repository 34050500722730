import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

export const Footer = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.container}>
        {/* <div className={styles.link__column}>
          <strong>Additional Information</strong>
          <Link to="/about">About Us</Link>
          <a href="./sitemap.xml">Site Map</a>
        </div> */}
        <div className={styles.link__column}>
          <strong>Resources</strong>
          <a href="https://feedback.fictionsoftware.com" target="_blank">
            Roadmap
          </a>
          <Link to="/contact">Contact Us</Link>
          <a href="https://help.fictionsoftware.com" target="_blank">
            Help
          </a>
        </div>
        <div className={styles.link__column}>
          <strong>Legal</strong>
          <Link to="/terms">Terms of Use</Link>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
        <div className={styles.center}>
          <img src="./Fiction-Red-Bookmark_clearbg.png" alt="Fiction Software" className={styles.banner__logo} />
          <div>
            <strong>Fiction Software, LLC</strong>
          </div>
          <small>All content copyright © 2019 - {moment().year()}</small>
        </div>
      </div>
    </div>
  );
};
