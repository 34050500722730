import { Card, Size } from '@draftcraft/cards';
import React, { FC } from 'react';

import Markdown from 'react-markdown';
import styles from './index.module.scss';

export const BasicCardExample: FC = () => {
  const table1 = `
  | Enum Label  | Value |
  |-------------|-------|
  | Size.SMALL  | 100   |
  | Size.MEDIUM | 200   |
  | Size.LARGE  | 300   |
  | Size.XLARGE | 400   |
`;
  const table2 = `
| Props   | Type     | Required |
|---------|----------| -------- |
| size    | Number   | Yes      |
| imgUrl  | String   | No       |
| imgAlt  | String   | No       |
| shadow  | boolean  | No       |
| rounded | boolean  | No       |
`;
  return (
    <div className={styles.BasicCardExample}>
      <h2 className={styles.title}>Card</h2>
      <p>
        Given the nature of a card, the majority of features are predicated on a fixed width. Sizes of cards are
        calculated as 1.5 times the height of the width for a desireable ratio. As such the Size enum is a property that
        might be passed to many components. All components in this library can take a className and style prop to
        override any existing styles.
        <br />
        Possible values for the Size enum that is passed to the size prop in components:
      </p>
      <div className={styles.tables}>
        <Markdown source={table1} />
        <Markdown source={table2} />
      </div>
      <p>You can also simply provide any numerical value to the size prop.</p>
      <div className={styles.example}>
        <div>
          <strong>TS</strong>
          <pre>
            {String.raw`import { Card, Size } from '@draftcraft/cards';

export const Example = (props) => (
  <Card
    size={Size.LARGE}
    shadow
    rounded
    imgUrl={'https://upload.wikimedia.org/wikipedia/en/f/f1/Silk-comic_cover.jpg'}
    imgAlt={'Silk Superhero'}
    className={styles.card__image}
  >
    <div className={styles.card__title}>
      <h3>Silk</h3>
    </div>
    <div className={styles.card__content}>
      Cindy Moon is a fictional Korean American superhero, known as Silk,
      appearing in American comic books published by Marvel Comics.
    </div>
  </Card>
);`}
          </pre>
          <strong>CSS</strong>
          <pre>
            {String.raw`.card__image > div > img {
  height: 275px;
}
.card__title {
  background-color: #ff4a4b;
}
.card__title > h3 {
  padding: 5px;
  color: #fff;
  font-weight: 600;
}
.card__content {
  padding: 10px; 
}
`}
          </pre>
        </div>
        <div>
          <Card
            size={Size.LARGE}
            shadow
            rounded
            imgUrl={'https://upload.wikimedia.org/wikipedia/en/f/f1/Silk-comic_cover.jpg'}
            imgAlt={'Silk Superhero'}
            className={styles.card__image}
          >
            <div className={styles.card__title}>
              <h3>Silk</h3>
            </div>
            <div className={styles.card__content}>
              Cindy Moon is a fictional Korean American superhero, known as Silk, appearing in American comic books
              published by Marvel Comics.
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
