import React, { FC, useState, useEffect } from 'react';
import logo from 'assets/Fiction_Logo_Full_Red.png';
import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CheckCircleOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import axios from 'axios';

import styles from './index.module.scss';

const { Option } = Select;

const siteKey = '6LeqH18fAAAAAH85R9nHu6mpKeqr7oVrjb09MHOM';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  contactFlag?: boolean;
}

export const Feedback: FC<Props> = ({ contactFlag }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>();
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');

  // ADD Recaptcha script to the global window
  useEffect(() => {
    const id = 'recaptcha-key';
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.id = id;
    document.body.appendChild(script);

    return () => {
      const element = document.getElementById(id);
      (element as any).parentNode.removeChild(element);
    };
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    (window as any).grecaptcha.ready(() => {
      (window as any).grecaptcha.execute(siteKey, { action: 'submit' }).then((token: string) => {
        // Send form value as well as token to the server
        axios
          .get(
            `https://us-central1-draft-craft.cloudfunctions.net/email?subject=[${subject}] Feedback from ${email}&text=${body}&token=${token}`,
          )
          .then(() => setSuccess(true))
          .catch((error: any) => {
            setSuccess(false);
            console.error(error);
          })
          .finally(() => setLoading(false));
      });
    });
  };

  return (
    <div className={styles.Feedback}>
      <div className={styles.header}>
        <img src={logo} alt="Fiction Software" className={styles.logo} />
      </div>
      <div className={styles.container}>
        <div className={styles.form}>
          <h1>{!contactFlag ? 'Submit Feedback' : 'Contact Us'}</h1>
          {success === true ? (
            <div className={styles.success}>
              <CheckCircleOutlined className={styles.check} />
              Your feedback was submitted successfully.
            </div>
          ) : (
            <Form layout="vertical" onFinish={handleSubmit}>
              <Form.Item name="reply-email" label="Email Address">
                <Input
                  placeholder="Email Address (If you would like a response)"
                  id="reply-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="feedback-type"
                label={!contactFlag ? 'Type of Feedback' : 'Subject'}
                rules={[{ required: true, message: `Please select a ${!contactFlag ? 'feedback type' : 'subject'}` }]}
              >
                {!contactFlag ? (
                  <Select id="feedback-type" placeholder="Select an option" onChange={setSubject}>
                    <Option value="BUG">Bug</Option>
                    <Option value="FEATURE-REQUEST">Feature Request</Option>
                    <Option value="OTHER">Other</Option>
                  </Select>
                ) : (
                  <Input
                    placeholder="Subject"
                    id="feedback-type"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                )}
              </Form.Item>
              <Form.Item name="body" label="Message" rules={[{ required: true, message: 'Please provide a message' }]}>
                <TextArea
                  placeholder="Type your message here..."
                  id="body"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  rows={6}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" shape="round" className={styles.button} disabled={loading}>
                  {loading === true ? <LoadingOutlined /> : <MailOutlined />}
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}

          {success === false && (
            <div className={styles.error}>Unable to submit your feedback. Please try again later.</div>
          )}
        </div>
      </div>
    </div>
  );
};
