import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  children: JSX.Element;
}

export const ScrollToTop: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};
