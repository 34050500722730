import React, { FC } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './index.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const Quote: FC<Props> = () => {
  return (
    <div className={styles.Quote}>
      <ScrollAnimation animateIn="fadeIn">
        <div>On writing a story:</div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInRight">
        <h2>“You do 90% of the work before the writing begins.”</h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInLeft">
        <img src="/niell-blomkamp.png" alt="Niell BlomKamp" className={styles.img} />
        <div className={styles.attribution}>- Neill Blomkamp</div>
        <div className={styles.attribution}>
          <small>Film director and screenwriter</small>
        </div>
        <div className={styles.small}>District 9, Elysium, and Chappie</div>
      </ScrollAnimation>
    </div>
  );
};
