import {
  retrieveUserQuickWinAccomplished,
  retrieveUserSubscriptions,
  setUser,
  setUserActivated,
} from '../actions/user';
import { finishCharacterOnboarding } from 'stores/actions/characters';

import { createReducer } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import 'firebase/auth';

export interface State {
  user?: firebase.User;
  activated?: boolean;
  subscriber?: boolean;
  quickWinAccomplished?: boolean;
}

const initialState: State = {
  subscriber: undefined,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUser, (state, { payload }) => ({
    ...state,
    user: payload.user,
  }));
  builder.addCase(setUserActivated.fulfilled, (state, { payload }) => ({
    ...state,
    activated: payload?.activated ?? false,
  }));
  builder.addCase(setUserActivated.rejected, (state) => ({
    ...state,
    activated: false,
  }));
  builder.addCase(retrieveUserSubscriptions.fulfilled, (state, { payload }) => ({
    ...state,
    subscriber: payload.some((subscription) => subscription.status === 'active' || subscription.status === 'trialing'),
  }));
  builder.addCase(retrieveUserQuickWinAccomplished.fulfilled, (state, { payload }) => ({
    ...state,
    quickWinAccomplished: payload?.quickWinAccomplished,
  }));
  builder.addCase(finishCharacterOnboarding.fulfilled, (state) => ({
    ...state,
    quickWinAccomplished: true,
  }));
});
