import {
  createStoryboard,
  fetchStoryboards,
  saveStoryboard,
  deleteStoryboard,
  setEditingStoryboards,
  setIsCreating,
} from '../actions/storyboards';

import { createReducer } from '@reduxjs/toolkit';
import { Storyboard } from 'models/interfaces/storyboard';

export interface State {
  storyboards: Partial<Storyboard>[];
}

const initialState: State = {
  storyboards: [],
};

export const storyboardsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createStoryboard.pending, (state, { meta }) => ({
      ...state,
      storyboards: [meta.arg, ...state.storyboards],
    }))
    // .addCase(createStoryboard.fulfilled, (state, { payload }) => ({
    //   ...state,
    //   storyboards: [payload, ...state.storyboards],
    // }))
    .addCase(fetchStoryboards.fulfilled, (state, { payload }) => ({
      ...state,
      storyboards: payload,
    }))
    .addCase(saveStoryboard.pending, (state, { meta }) => ({
      ...state,
      storyboards: state.storyboards.map((storyboard) => (storyboard.id === meta.arg.id ? meta.arg : storyboard)),
    }))
    .addCase(saveStoryboard.fulfilled, (state, { payload }) => ({
      ...state,
      storyboards: state.storyboards.map((storyboard) => (storyboard.id === payload.id ? payload : storyboard)),
    }))
    .addCase(deleteStoryboard.fulfilled, (state, { payload }) => ({
      ...state,
      storyboards: state.storyboards.filter((storyboard) => storyboard.id !== payload),
    }))
    .addCase(setEditingStoryboards, (state, { payload }) => ({
      ...state,
      isEditing: payload,
    }))

    .addCase(setIsCreating, (state, { payload }) => ({
      ...state,
      isCreating: payload,
    }));
});
