import { Setting } from 'models/interfaces/setting';
import { createReducer } from '@reduxjs/toolkit';
import { createNewSetting, deleteExistingSetting, fetchSettings, saveSetting } from '../actions/settings';

export interface SettingState {
  settings: Partial<Setting>[];
}

const forestSetting: Setting = {
  name: 'Forest',
  location: 'Albania',
  created: 1644087274095,
  description: 'Spoopy and scary',
  id: '2d2b880f-06d2-41a9-b4ec-7555a884bf59',
};

const citySetting: Setting = {
  name: 'City',
  location: 'Kazakhstan',
  created: 1644087274095,
  description: 'Lively and busy',
  id: 'b2d9fb3f-245a-4e3e-909b-9c45b22d64ab',
};

const dessertSetting: Setting = {
  name: 'Dessert',
  location: 'Land of Treats',
  created: 1644087274095,
  description: 'Nom nom',
  id: '0d955b85-3735-4491-b6e6-c41f6fa12376',
};

const poolSetting: Setting = {
  name: 'Beachside',
  location: 'Unknown',
  created: 1644087274095,
  description: 'Cozy',
  id: '0aa371a1-9c61-46c6-8547-86a4c8bbfe4a',
};

const initialState: SettingState = {
  settings: [forestSetting, citySetting, dessertSetting, poolSetting],
};

export const settingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchSettings.fulfilled, (state, { payload }) => ({
    ...state,
    settings: payload,
  }));

  builder.addCase(createNewSetting.fulfilled, (state, { payload }) => ({
    ...state,
    settings: [...state.settings, payload],
  }));

  builder.addCase(saveSetting.fulfilled, (state, { payload }) => ({
    ...state,
    settings: state.settings.map((setting) => (setting.id === payload.id ? payload : setting)),
  }));

  builder.addCase(deleteExistingSetting.fulfilled, (state, { payload }) => ({
    ...state,
    settings: state.settings.filter((setting) => setting.id !== payload),
  }));
});
