import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { Errors } from 'models/enums/error';
import { RelationshipGroup } from 'models/interfaces/relationships';
import { ReactFlowJsonObject, Edge } from 'react-flow-renderer';
import {
  firebaseUpdateDocument,
  firebaseCreateDocument,
  firebaseDeleteDocument,
  firebaseCollectionRequest,
  getRelationshipsPath,
  firebaseDocumentRequest,
} from 'services/database-service';
import { getProjectId } from './characters';
import { getUserId } from './projects';

// helpers
const saveExistingRelationshipGroupBase = (actionName = 'relationships/saveRelationshipGroup') =>
  createAsyncThunk<Partial<RelationshipGroup>, Partial<RelationshipGroup>>(
    actionName,
    (relationshipGroup, ThunkAPI) => {
      const userId = getUserId(ThunkAPI);
      const projectId = getProjectId(ThunkAPI);
      if (userId !== undefined && projectId !== undefined) {
        return firebaseUpdateDocument(getRelationshipsPath(userId, projectId), relationshipGroup);
      } else {
        throw Errors.USER_OR_PROJECT_UNDEFINED;
      }
    },
  );

// Action Creators
export const updateRelationshipGroupIds = createAction<{
  relationshipGroupId: string;
  attribute: string;
  value: string[];
}>('relationships/updateAttribute');
export const updateRelationshipFlow = createAction<{
  relationshipGroupId: string;
  flow: ReactFlowJsonObject<any, any>;
}>('relationships/updateFlow');
export const deleteEdge = createAction<{
  relationshipGroupId: string;
  edgeId: string;
}>('relationships/deleteEdge');
export const updateEdgeColor = createAction<{
  relationshipGroupId: string;
  edge: Edge | undefined;
  color: string;
}>('relationships/updateEdgeColor');
export const updateEdgeLabel = createAction<{
  relationshipGroupId: string;
  edgeId: string;
  label: string;
}>('relationships/updateEdgeLabel');
export const updateEdgeType = createAction<{
  relationshipGroupId: string;
  edge: Edge | undefined;
  type: string;
}>('relationships/updateEdgeType');

//Async Action Creators

// createNewRelationshipGroup,
export const createNewRelationshipGroup = createAsyncThunk<Partial<RelationshipGroup>, Partial<RelationshipGroup>>(
  'relationships/createRelationshipGroup',
  (relationshipGroup: Partial<RelationshipGroup>, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    const projectId = getProjectId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined) {
      firebase.analytics().logEvent('create_content', { content_type: 'relationshipGroup' });
      return firebaseCreateDocument<Partial<RelationshipGroup>>(
        getRelationshipsPath(userId, projectId),
        relationshipGroup,
      );
    } else {
      throw Errors.USER_OR_PROJECT_UNDEFINED;
    }
  },
);

// deleteExistingRelationshipGroup,
export const deleteExistingRelationshipGroup = createAsyncThunk(
  'relationships/deleteRelationshipGroup',
  (relationshipGroupId: string, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    const projectId = getProjectId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined && relationshipGroupId !== undefined) {
      return firebaseDeleteDocument(getRelationshipsPath(userId, projectId), relationshipGroupId);
    } else {
      throw Errors.USER_PROJECT_OR_ASSET_UNDEFINED;
    }
  },
);

// fetchRelationshipGroup,
export const fetchRelationshipGroup = createAsyncThunk<
  Partial<RelationshipGroup>,
  { projectId: string; relationshipGroupId: string }
>('relationships/fetchRelationshipGroup', ({ projectId, relationshipGroupId }, ThunkAPI) => {
  const userId = getUserId(ThunkAPI) ?? '';
  return firebaseDocumentRequest<Partial<RelationshipGroup>>(
    getRelationshipsPath(userId, projectId),
    relationshipGroupId,
  );
});

// fetchRelationshipGroups,
export const fetchRelationshipGroups = createAsyncThunk<Partial<RelationshipGroup>[], string>(
  'relationships/fetchRelationshipGroups',
  (projectId, ThunkAPI) => {
    const userId = getUserId(ThunkAPI) ?? '';
    return firebaseCollectionRequest<Partial<RelationshipGroup>>(getRelationshipsPath(userId, projectId));
  },
);

// saveExistingRelationshipGroupOptimisticStore,
export const saveExistingRelationshipGroup = saveExistingRelationshipGroupBase(
  'relationships/saveRelationshipGroup/FirebaseOnly',
);

// saveExistingRelationshipGroupOptimisticStore,
export const saveExistingRelationshipGroupOptimisticStore = saveExistingRelationshipGroupBase(
  'relationships/saveRelationshipGroup/LocalAndFirebase',
);
