import styled, { keyframes } from 'styled-components';

import React from 'react';
import logo from 'assets/Fiction-Red-Bookmark_clearbg.png';
import { pulse } from 'react-animations';
import styles from './index.module.scss';

export const Loading = () => {
  const Heartbeat = styled.div`
    animation: infinite 0.4s ${keyframes`${pulse}`};
  `;

  return (
    <div className={styles.Loading}>
      <Heartbeat>
        <img src={logo} alt="Fiction Software" className={styles.logo} />
      </Heartbeat>
    </div>
  );
};
