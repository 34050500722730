import { Edge } from 'react-flow-renderer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColorCode } from 'models/enums/color-code';
import { LineTypes } from 'models/enums/line-types';

interface RelationshipLineOptionsState {
  lineColor: ColorCode;
  lineType: LineTypes;
  selectedEdge: Edge | undefined;
}

const initialState = {
  lineColor: ColorCode.GRAY,
  lineType: LineTypes.SOLID,
  selectedEdge: undefined,
} as RelationshipLineOptionsState;

const relationshipLineOptionsSlice = createSlice({
  name: 'relationshiplineoptions',
  initialState,
  reducers: {
    setLineColor(state, action: PayloadAction<ColorCode>) {
      return { ...state, lineColor: action.payload };
    },
    setLineType(state, action: PayloadAction<LineTypes>) {
      return { ...state, lineType: action.payload };
    },
    setSelectedEdge(state, action: PayloadAction<Edge | undefined>) {
      return { ...state, selectedEdge: action.payload };
    },
  },
});

export const { setLineColor, setLineType, setSelectedEdge } = relationshipLineOptionsSlice.actions;
export const relationshipLineOptionsReducer = relationshipLineOptionsSlice.reducer;
