export enum ColorCode {
  'RED' = '#FF4B4B',
  'ORANGE' = '#FF8D4B',
  'YELLOW' = '#EFB401',
  'GREEN' = '#45D07F',
  'BLUE' = '#4BA5FF',
  'VIOLET' = '#6565FF',
  'PINK' = '#DD3CA7',
  'PURPLE' = '#990099',
  'GRAY' = '#414545',
}
