import './index.css';
import 'firebase/analytics';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import firebaseConfig from './constants';
import store from './stores/congifure-store';
import { ScrollToTop } from 'components/common/ScrollToTop';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
