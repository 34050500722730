import { createReducer } from '@reduxjs/toolkit';
import { createNewArtifact, deleteExistingArtifact, fetchArtifacts, saveArtifact } from 'stores/actions/artifacts';
import { Artifact } from 'models/interfaces/artifact';

export interface ArtifactsState {
  artifacts: Partial<Artifact>[];
}

const initialState: ArtifactsState = {
  artifacts: [],
};

export const artifactsReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchArtifacts.fulfilled, (state, { payload }) => ({
    ...state,
    artifacts: payload,
  }));

  builder.addCase(createNewArtifact.fulfilled, (state, { payload }) => ({
    ...state,
    artifacts: [...state.artifacts, payload],
  }));

  builder.addCase(saveArtifact.fulfilled, (state, { payload }) => ({
    ...state,
    artifacts: state.artifacts.map((setting) => (setting.id === payload.id ? payload : setting)),
  }));

  builder.addCase(deleteExistingArtifact.fulfilled, (state, { payload }) => ({
    ...state,
    artifacts: state.artifacts.filter((setting) => setting.id !== payload),
  }));
});
