import { Action, Shape } from '@draftcraft/cards';
import React, { FC } from 'react';

import Markdown from 'react-markdown';
import styles from './index.module.scss';

export const ActionExample: FC = () => {
  const table1 = `
  | Props   | Type     | Required |
  |---------|----------| -------- |
  | shape   | Shape   | No       |
  | hoverable  | boolean   | No       |
  | hoverColor  | String   | No       |
  `;

  const table2 = `
  | Enum Label  |
  |-------------|
  | Shape.CIRCLE  |
  | Shape.RECTANGLE |
`;

  return (
    <div className={styles.ActionExample}>
      <h2 className={styles.title}>Action</h2>
      <p>
        An Action is a design component that is used as a call to action. It will render any children provided to it as
        a stylized action. It can be either square or circular and can be used trigger a hover event. The default shape
        is square and the default value for hoverable is false. The following are the Props available for the Action
        component and the associated enum values for the Shape type:
      </p>
      <div className={styles.tables}>
        <Markdown source={table1} />
        <Markdown source={table2} />
      </div>
      <div className={styles.example}>
        <div>
          <strong>TS</strong>
          <pre>
            {String.raw`import { Action, Shape } from '@draftcraft/cards';

export const Example = (props) => (
  <Action shape={Shape.CIRCLE} hoverColor={'#ff4b4b'} hoverable>
    <h2 style={{ color: '#ff4b4b' }}>$</h2>
  </Action>
  <Action style={{ width: '115px', height: '115px' }}>
    <h2 style={{ color: '#ff4b4b', textAlign: 'center' }}>Call to Action</h2>
  </Action>
);`}
          </pre>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Action shape={Shape.CIRCLE} hoverColor={'#ff4b4b'} hoverable>
            <h2 style={{ color: '#ff4b4b' }}>$</h2>
          </Action>
          <Action style={{ width: '115px', height: '115px' }}>
            <h2 style={{ color: '#ff4b4b', textAlign: 'center' }}>Call to Action</h2>
          </Action>
        </div>
      </div>
    </div>
  );
};
