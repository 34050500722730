import { createSlice } from '@reduxjs/toolkit';

import {
  createTimelineFromPartial,
  isTimelineSetting,
  TimelineModel,
  TimelineSetting,
} from 'models/interfaces/timeline';
import {
  addEventToSetting,
  addLabel,
  addSettings,
  createNewTimeline,
  deleteEventFromSetting,
  deleteExistingTimeline,
  deleteSettingOrLabel,
  updateEventOnSetting,
  fetchTimeline,
  fetchTimelines,
  saveExistingTimelineOptimisticStore,
  setLabelTitle,
  setSettings,
  deleteSettingsOrLabels,
  setColumnWidth,
  updateGranularity,
} from 'stores/actions/timelines';

export interface TimelinesState {
  timelines: TimelineModel[];
  loading: boolean;
}

export const initialState: TimelinesState = {
  timelines: [],
  loading: false,
};

export const timelinesSlice = createSlice({
  name: 'timelines',
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(updateGranularity, (state, { payload }) => ({
      ...state,
      timelines: state.timelines.map((timeline) =>
        timeline.id === payload.timelineId ? { ...timeline, granularity: payload.granularity } : timeline,
      ),
    }));
    builder.addCase(setColumnWidth, (state, { payload }) => ({
      ...state,
      timelines: state.timelines.map((timeline) =>
        timeline.id === payload.timelineId ? { ...timeline, columnWidth: payload.columnWidth } : timeline,
      ),
    }));
    builder.addCase(addEventToSetting, (state, { payload }) => ({
      ...state,
      timelines: state.timelines.map((timeline) =>
        timeline.id === payload.timelineId
          ? {
              ...timeline,
              settings: timeline.settings.map((settingOrLabel) =>
                settingOrLabel.id === payload.settingId && isTimelineSetting(settingOrLabel)
                  ? { ...settingOrLabel, events: [...(settingOrLabel?.events ?? []), payload.newEvent] }
                  : settingOrLabel,
              ),
            }
          : timeline,
      ),
    }));
    builder.addCase(updateEventOnSetting, (state, { payload }) => ({
      ...state,
      timelines: state.timelines.map((timeline) =>
        timeline.id === payload.timelineId
          ? {
              ...timeline,
              settings: timeline.settings.map((settingOrLabel) =>
                settingOrLabel.id === payload.settingId && isTimelineSetting(settingOrLabel)
                  ? {
                      ...settingOrLabel,
                      events: (settingOrLabel?.events ?? []).map((event) =>
                        event.id === payload.newEvent.id ? payload.newEvent : event,
                      ),
                    }
                  : settingOrLabel,
              ),
            }
          : timeline,
      ),
    }));
    builder.addCase(deleteEventFromSetting, (state, { payload }) => ({
      ...state,
      timelines: state.timelines.map((timeline) =>
        timeline.id === payload.timelineId
          ? {
              ...timeline,
              settings: timeline.settings.map((settingOrLabel) =>
                settingOrLabel.id === payload.settingId && isTimelineSetting(settingOrLabel)
                  ? {
                      ...settingOrLabel,
                      events: (settingOrLabel?.events ?? []).filter((event) => event.id !== payload.eventId),
                    }
                  : settingOrLabel,
              ),
            }
          : timeline,
      ),
    }));
    builder.addCase(addSettings, (state, { payload }) => {
      const newTimelines = state.timelines.map((timeline) => {
        const isTimelineToUpdate = timeline.id === payload.timelineId;
        if (isTimelineToUpdate) {
          const settings: TimelineSetting[] = payload.settingIds.map((settingId) => ({
            type: 'setting',
            id: settingId,
            events: [],
          }));
          return { ...timeline, settings: [...timeline.settings, ...settings] };
        }
        return timeline;
      });
      return { ...state, timelines: newTimelines };
    });
    builder.addCase(addLabel, (state, { payload }) => {
      const newTimelines = state.timelines.map((timeline) => {
        const isTimelineToUpdate = timeline.id === payload.timelineId;
        if (isTimelineToUpdate) {
          return { ...timeline, settings: [...timeline.settings, payload.label] };
        }
        return timeline;
      });
      return { ...state, timelines: newTimelines };
    });
    builder.addCase(deleteSettingOrLabel, (state, { payload }) => {
      const newTimelines = state.timelines.map((timeline) => {
        const isTimelineToUpdate = timeline.id === payload.timelineId;
        if (isTimelineToUpdate) {
          const newSettings = timeline.settings.filter(
            (settingOrLabel) => settingOrLabel.id !== payload.labelOrSettingId,
          );
          return { ...timeline, settings: newSettings };
        }
        return timeline;
      });
      return { ...state, timelines: newTimelines };
    });
    builder.addCase(deleteSettingsOrLabels, (state, { payload }) => {
      const newTimelines = state.timelines.map((timeline) => {
        const isTimelineToUpdate = timeline.id === payload.timelineId;
        if (isTimelineToUpdate) {
          const newSettings = timeline.settings.filter(
            (settingOrLabel) => !payload.labelOrSettingIds?.includes(settingOrLabel.id),
          );
          return { ...timeline, settings: newSettings };
        }
        return timeline;
      });
      return { ...state, timelines: newTimelines };
    });
    builder.addCase(setSettings, (state, { payload }) => {
      const newTimelines = state.timelines.map((timeline) => {
        const isTimelineToUpdate = timeline.id === payload.timelineId;
        if (isTimelineToUpdate) {
          return { ...timeline, settings: payload.settings };
        }
        return timeline;
      });
      return { ...state, timelines: newTimelines };
    });
    builder.addCase(setLabelTitle, (state, { payload }) => ({
      ...state,
      timelines: state.timelines.map((timeline) =>
        timeline.id === payload.timelineId
          ? {
              ...timeline,
              settings: timeline.settings.map((settingOrLabel) =>
                settingOrLabel.id === payload.labelId ? { ...settingOrLabel, title: payload.newName } : settingOrLabel,
              ),
            }
          : timeline,
      ),
    }));
    builder.addCase(fetchTimelines.pending, (state) => ({ ...state, loading: true }));
    builder.addCase(fetchTimelines.rejected, (state) => ({ ...state, loading: false }));
    builder.addCase(fetchTimelines.fulfilled, (state, { payload }) => {
      const timelines: TimelineModel[] = payload.map((timeline) => createTimelineFromPartial(timeline));
      return { ...state, loading: false, timelines: timelines };
    });
    builder.addCase(fetchTimeline.pending, (state) => ({ ...state, loading: true }));
    builder.addCase(fetchTimeline.rejected, (state) => ({ ...state, loading: false }));
    builder.addCase(fetchTimeline.fulfilled, (state, { payload }) => {
      const timeline: TimelineModel = createTimelineFromPartial(payload);
      const timelineExists = state.timelines.some((t) => t.id === timeline.id);
      return {
        ...state,
        loading: false,
        timelines: timelineExists
          ? state.timelines.map((t) => (t.id === timeline.id ? timeline : t))
          : [...state.timelines, timeline],
      };
    });
    builder.addCase(createNewTimeline.pending, (state) => ({ ...state, loading: true }));
    builder.addCase(createNewTimeline.rejected, (state) => ({ ...state, loading: false }));
    builder.addCase(createNewTimeline.fulfilled, (state, { payload }) => {
      const timeline: TimelineModel = createTimelineFromPartial(payload);
      return { ...state, loading: false, timelines: [...state.timelines, timeline] };
    });
    builder.addCase(deleteExistingTimeline.pending, (state) => ({ ...state, loading: true }));
    builder.addCase(deleteExistingTimeline.rejected, (state) => ({ ...state, loading: false }));
    builder.addCase(deleteExistingTimeline.fulfilled, (state, { payload: deletedId }) => {
      const updatedTimelines = state.timelines.filter((timeline) => timeline.id !== deletedId);
      return { ...state, loading: false, timelines: updatedTimelines };
    });
    builder.addCase(saveExistingTimelineOptimisticStore.fulfilled, (state, { payload }) => {
      const updatedTimeline: TimelineModel = createTimelineFromPartial(payload);
      const updatedTimelines = state.timelines.map((timeline) =>
        timeline.id === updatedTimeline.id ? updatedTimeline : timeline,
      );
      return { ...state, loading: false, timelines: updatedTimelines };
    });
    builder.addCase(saveExistingTimelineOptimisticStore.pending, (state, action) => {
      const updatingTimeline: TimelineModel = createTimelineFromPartial(action.meta.arg);
      const updatedTimelines = state.timelines.map((timeline) =>
        timeline.id === updatingTimeline.id ? updatingTimeline : timeline,
      );
      return { ...state, loading: true, timelines: updatedTimelines };
    });
    builder.addCase(saveExistingTimelineOptimisticStore.rejected, (state) => ({ ...state, loading: false }));
  },
});

export const timelinesReducer = timelinesSlice.reducer;
