import 'firebase/auth';
import 'firebase/analytics';

import { FacebookOutlined, GoogleOutlined, MinusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { Button } from 'antd';
import firebase from 'firebase/app';
import logo from 'assets/Fiction_Logo_Full_Red.png';
import styles from './index.module.scss';
import { useLocation } from 'react-router';

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const loginWithGoogle = () => {
  firebase.auth().signInWithRedirect(googleAuthProvider);
  firebase.analytics().logEvent('login', { method: 'google' });
};

const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
facebookAuthProvider.addScope('public_profile');
facebookAuthProvider.addScope('email');
const loginWithFacebook = () => {
  firebase.auth().signInWithRedirect(facebookAuthProvider);
  firebase.analytics().logEvent('login', { method: 'facebook' });
};

export const Login = () => {
  const location = useLocation();
  const login = new URLSearchParams(location.search).get('login');
  const [createOrLogin, setCreateOrLogin] = useState<'Create an account' | 'Login'>(
    login === 'true' ? 'Login' : 'Create an account',
  );

  return (
    <div className={styles.Login}>
      <div className={styles.container}>
        <img src={logo} alt="Fiction Software" className={styles.logo} />
        {/* {!login && createOrLogin !== 'Login' && <h1>Join the waitlist by creating an account</h1>} */}
        <h3>{createOrLogin} with one of the following</h3>
        <div>
          <Button shape="round" size="large" ghost className={styles.button} onClick={loginWithFacebook}>
            <FacebookOutlined />
            {createOrLogin} with Facebook
          </Button>
        </div>
        <div>
          <Button shape="round" size="large" ghost className={styles.button} onClick={loginWithGoogle}>
            <GoogleOutlined />
            {createOrLogin} with Google
          </Button>
        </div>
        <div>
          <small>
            By logging in you agree to the <a href="/terms">terms of use</a> and <a href="/privacy">privacy policy</a>.
          </small>
        </div>
        <MinusOutlined className={styles.divider} />
        {createOrLogin === 'Create an account' ? (
          <>
            <h3 className={styles.space}>Already have an account?</h3>
            <Button type="link" onClick={() => setCreateOrLogin('Login')}>
              Login Here
            </Button>
          </>
        ) : (
          <>
            <h3 className={styles.space}>Don't have an account?</h3>
            <Button type="link" onClick={() => setCreateOrLogin('Create an account')}>
              Create One Here
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
