import { createAsyncThunk } from '@reduxjs/toolkit';
import { Errors } from '../../models/enums/error';
import { Chapter } from '../../models/interfaces/chapter';
import {
  firebaseCreateDocument,
  firebaseDocumentRequest,
  firebaseUpdateDocument,
  getChaptersPath,
} from '../../services/database-service';
import { getProjectId } from './characters';
import { getUserId } from './projects';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { createAction } from '@reduxjs/toolkit';
import { CHAPTER_ID } from '../../models/interfaces/chapter';

/**
 * Create a new Chapter
 */
export const createNewChapter = createAsyncThunk<
  { chapters: Partial<Chapter>[]; editing: string },
  { chapters: Partial<Chapter>[]; editing: string }
>('NEW_CHAPTER', async ({ chapters, editing }, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  const projectId = getProjectId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined) {
    firebase.analytics().logEvent('create_content', { content_type: 'chapters' });
    const response = await firebaseCreateDocument<{ id: string; chapters: Partial<Chapter>[] }>(
      getChaptersPath(userId, projectId),
      {
        id: CHAPTER_ID,
        chapters,
      },
    );

    return { chapters: response.chapters, editing };
  } else {
    throw Errors.USER_OR_PROJECT_UNDEFINED;
  }
});

//Async Action Creators
/**
 * Fetch all chapters from the Chapters collection
 */

export const fetchChapters = createAsyncThunk<{ id: string; chapters: Partial<Chapter>[] }, string>(
  'FETCH_CHAPTERS',
  (projectId, ThunkAPI) => {
    const userId = getUserId(ThunkAPI);
    if (userId !== undefined && projectId !== undefined) {
      return firebaseDocumentRequest<{ id: string; chapters: Partial<Chapter>[] }>(
        getChaptersPath(userId, projectId),
        CHAPTER_ID,
      );
    } else {
      throw Errors.USER_OR_PROJECT_UNDEFINED;
    }
  },
);

/**
 * Update the Chapters array
 */
export const saveChapter = createAsyncThunk<
  { chapters: Partial<Chapter>[]; editing?: string },
  { chapters: Partial<Chapter>[]; editing?: string }
>('UPDATE_CHAPTER', async ({ chapters, editing }, ThunkAPI) => {
  const userId = getUserId(ThunkAPI);
  const projectId = getProjectId(ThunkAPI);
  if (userId !== undefined && projectId !== undefined) {
    firebase.analytics().logEvent('update_content', { content_type: 'chapters' });
    const response = await firebaseUpdateDocument<{ id: string; chapters: Partial<Chapter>[] }>(
      getChaptersPath(userId, projectId),
      {
        id: CHAPTER_ID,
        chapters: chapters,
      },
    );

    return { chapters: response.chapters, editing };
  } else {
    throw Errors.USER_OR_PROJECT_UNDEFINED;
  }
});

export const setEditing = createAction<string | undefined>('SET_EDITING');
export const setChapters = createAction<Partial<Chapter>[]>('SET_CHAPTERS');
