import 'animate.css/animate.min.css';
import '@draftcraft/cards/dist/index.css';
import 'antd/dist/antd.less';

import { Route, Routes } from 'react-router-dom';

import { Cards } from './components/public/developer/cards';
import { EntryPoint } from './components/authenticated/entry-point';
import React from 'react';
import { Welcome } from './components/public/welcome';
import { NotFound } from 'components/common/NotFound';
import { Terms } from 'components/public/Terms';
import { Privacy } from 'components/public/Privacy';
import { Feedback } from 'components/public/Feedback';
import { Loading } from 'components/common/loading';
const PricingPlans = React.lazy(() => import('components/public/pricing-plans'));

import styles from './App.module.scss';
import { Subscribed } from 'components/public/subscribed';

const App: React.FC = () => {
  return (
    <div className={styles.App}>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/projects/*" element={<EntryPoint />} />
        <Route path="/cards" element={<Cards />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/contact" element={<Feedback contactFlag={true} />} />
        <Route
          path="/pricing"
          element={
            <React.Suspense fallback={<Loading />}>
              <PricingPlans />
            </React.Suspense>
          }
        />
        <Route path="/subscribed" element={<Subscribed />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
